import { combineReducers } from 'redux'

import orderService from './order/orderService'
import * as orderSelectors from './order/orderService.selectors'

import voucherService from './voucher/voucherService'
import * as voucherSelectors from './voucher/voucherService.selectors'

import * as helpers from './orderService.helpers'

export const orderServiceHelpers = {
  ...helpers,
}

export const orderServiceSelectors = {
  ...orderSelectors,
  ...voucherSelectors,
}

export const orderServiceReducers = combineReducers({
  order: orderService,
  voucher: voucherService,
})
