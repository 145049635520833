import { HEADERS } from '@probatix/common/constants/http'
import { getBasicSingleAction, ActionParams } from '@probatix/common/utils/service'

import { slice } from './translationsService'

const URL = '/translations'

export const {
  translationsFailed,
  translationsFetching,
  translationsSuccess,
} = slice.actions

export const get = (params: ActionParams<object, object, { locale: string }>): object => getBasicSingleAction({
  customHeaders: {
    [HEADERS.ACCEPT_LANGUAGE]: params?.meta?.locale,
  },
  onError: translationsFailed.type,
  onInit: translationsFetching.type,
  onSuccess: translationsSuccess.type,
  url: URL,
  ...params,
})
