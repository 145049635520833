import { ActionParams, getBasicListAction, getBasicSliceActions } from '@probatix/common/utils/service'
import { API_MY_PROBATIX_ENTRYPOINT } from '@probatix/common/config/app'
import { slice } from './userListService'

export const {
  setUsers,
  usersListFailed,
  usersListFetching,
  usersListReset,
  usersListSuccess,
} = getBasicSliceActions(slice.actions)

// TODO: move this to rtkQuery
export const listAccountUsers = (
  params: ActionParams<any, { criteria }> = {},
): object => getBasicListAction({
  customEntrypoint: API_MY_PROBATIX_ENTRYPOINT,
  onError: usersListFailed.type,
  onInit: usersListFetching.type,
  onSuccess: usersListSuccess.type,
  url: 'patients',
  ...params,
})
