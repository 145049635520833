import styled from 'styled-components'
import { Col } from 'react-bootstrap'

interface IStyledCol {
  margin?: string;
  padding?: string;
}

export const StyledCol = styled(Col)<IStyledCol>`
   ${(props) => props.margin && ` margin: ${props.margin}; `};
   ${(props) => props.padding && ` padding: ${props.padding}; `};
`
