import { createSelector } from 'reselect'

import { Nullable, NullableNumber, NullableString } from '@probatix/common/models/global'

import { Customer } from '@probatix/common/services/RTKServices/patients/index.model'
import { Product } from '@probatix/common/services/RTKServices/checkoutSteps/checkoutStepsService.model'
import { Location } from '@probatix/common/services/RTKServices/locations/index.model'
import { GeoJsonProperties } from 'geojson'

import { productServiceSelectors } from '@probatix/common/services/toolkit/product'

import { FlowType, TestsFormServiceState, TestsServiceRootState } from '../testsService.model'

export const selectTestsFormState = (state: TestsServiceRootState): TestsFormServiceState => state.tests.form

export const selectTestsFormLocation = createSelector(
  [selectTestsFormState],
  (state): Nullable<Location> => state.location,
)

export const selectTestsFormBaseProduct = createSelector(
  [selectTestsFormState],
  (state): Nullable<Product> => state.baseProduct,
)

export const selectTestsFormBaseProductName = createSelector(
  [selectTestsFormState],
  (state): NullableString => state.product?.baseProductName,
)

export const selectTestsFormProduct = createSelector(
  [selectTestsFormState],
  (state): Nullable<Product> => state.product,
)

export const selectTestsBetaFreeTest = createSelector(
  [selectTestsFormState],
  (state): boolean => state.enabledTest!,
)

export const selectTestsExplode = createSelector(
  [selectTestsFormState],
  (state): boolean => state.explode!,
)

export const selectTestsFormStore = createSelector(
  [selectTestsFormState],
  (state): Nullable<GeoJsonProperties> => state.store,
)

export const selectTestsFormPerson = createSelector(
  [selectTestsFormState],
  (state): Nullable<Customer> => state.person,
)

export const selectTestsFormPersonEmail = createSelector(
  [selectTestsFormState],
  (state): string => state!.person?.email!,
)

export const selectTestsFormTime = createSelector(
  [selectTestsFormState],
  (state): Nullable<Date> => state.time,
)

export const selectTestsFlow = createSelector(
  [selectTestsFormState],
  (state): FlowType => state.flow,
)

export const selectTestsCrossOutPrice = createSelector(
  [selectTestsFormState],
  (state): boolean => state.crossOutPrice,
)

export const selectTestsPublicCheckout = createSelector(
  [selectTestsFormState],
  (state): boolean => state.publicCheckout,
)

export const selectTestsCheckedProduct = createSelector(
  [selectTestsFormState],
  (state): boolean => state.checkedProduct,
)

export const selectIsBillingAddressSet = createSelector(
  [
    selectTestsFormPerson,
    (state, allSet: boolean = false) => allSet,
  ],
  (mainPerson, allSet): boolean => {
    if (!mainPerson) {
      return false
    }

    if (allSet) {
      return !!(
        mainPerson.billingAddressCity
        && mainPerson.billingAddressCountry
        && mainPerson.billingAddressStreet
        && mainPerson.billingAddressZipCode
        && mainPerson.company
      )
    }

    return !!(
      mainPerson.billingAddressCity
      || mainPerson.billingAddressCountry
      || mainPerson.billingAddressStreet
      || mainPerson.billingAddressZipCode
      || mainPerson.company
    )
  },
)

export const selectIsIdRequiredByProduct = createSelector(
  [productServiceSelectors.getProductListState, selectTestsFormProduct],
  ({ data: products }, product): boolean => {
    if (!product?.uuid || !products?.length) {
      return false
    }

    const selectedProduct = products.find((p) => p.uuid === product.uuid)
    if (!selectedProduct) {
      return false
    }

    return selectedProduct.isPersonalIdRequired!
  },
)

export const selectIsHealthInsurancePolicyNumberRequiredByProduct = createSelector(
  [productServiceSelectors.getProductListState, selectTestsFormProduct],
  ({ data: products }, product): boolean => {
    if (!product?.uuid || !products?.length) {
      return false
    }

    const selectedProduct = products.find((p) => p.uuid === product.uuid)
    if (!selectedProduct) {
      return false
    }

    return selectedProduct.healthInsurancePolicyNumberRequired!
  },
)

export const selectIsHealthInsuranceCompanyNumberRequiredByProduct = createSelector(
  [productServiceSelectors.getProductListState, selectTestsFormProduct],
  ({ data: products }, product): boolean => {
    if (!product?.uuid || !products?.length) {
      return false
    }

    const selectedProduct = products.find((p) => p.uuid === product.uuid)
    if (!selectedProduct) {
      return false
    }

    return selectedProduct.healthInsuranceCompanyNumberRequired!
  },
)

export const selectIsAddressRequiredRequiredByProduct = createSelector(
  [productServiceSelectors.getProductListState, selectTestsFormProduct],
  ({ data: products }, product): boolean => {
    if (!product?.uuid || !products?.length) {
      return false
    }

    const selectedProduct = products.find((p) => p.uuid === product.uuid)
    if (!selectedProduct) {
      return false
    }

    return selectedProduct.addressRequired!
  },
)

export const selectIsPhoneNumberRequiredByProduct = createSelector(
  [productServiceSelectors.getProductListState, selectTestsFormProduct],
  ({ data: products }, product): boolean => {
    if (!product?.uuid || !products?.length) {
      return false
    }

    const selectedProduct = products.find((p) => p.uuid === product.uuid)
    if (!selectedProduct) {
      return false
    }

    return selectedProduct.phoneNumberRequired!
  },
)

export const selectStoreBaseUrl = createSelector(
  [selectTestsFormStore],
  (store): string => {
    if (!store?.uuid) {
      return ''
    }

    return store.properties.instanceBaseUrl!
  },
)

export const selectLocationCode = createSelector(
  [selectTestsFormLocation],
  (location): string => {
    if (!location?.locationCode) {
      return ''
    }

    return location.locationCode!
  },
)

export const selectStoreLink = createSelector(
  [selectTestsFormStore],
  (store): string => {
    if (!store?.uuid) {
      return ''
    }

    return store.properties.link!
  },
)

export const selectIsIdRequiredByForm = createSelector(
  [selectTestsFormProduct],
  (product): boolean => {
    if (!product?.uuid) {
      return false
    }

    return product.isPersonalIdRequired!
  },
)

export const selectIsHealthInsurancePolicyNumberRequiredByForm = createSelector(
  [selectTestsFormProduct],
  (product): boolean => {
    if (!product?.uuid) {
      return false
    }

    return product.healthInsurancePolicyNumberRequired!
  },
)

export const selectIsHealthInsuranceCompanyNumberRequiredByForm = createSelector(
  [selectTestsFormProduct],
  (product): boolean => {
    if (!product?.uuid) {
      return false
    }

    return product.healthInsuranceCompanyNumberRequired
  },
)

export const selectIsAddressRequiredByForm = createSelector(
  [selectTestsFormProduct],
  (product): boolean => {
    if (!product?.uuid) {
      return false
    }

    return product.addressRequired!
  },
)

export const selectIsLocationCodeByLocation = createSelector(
  [selectTestsFormStore],
  (location): string => {
    if (!location?.locationCode) {
      return ''
    }

    return location.locationCode!
  },
)

export const selectIsUUIDByProduct = createSelector(
  [selectTestsFormProduct],
  (product): string => {
    if (!product?.uuid) {
      return ''
    }

    return product.uuid!
  },
)

export const selectIsUUIDByBaseProduct = createSelector(
  [selectTestsFormBaseProduct],
  (baseProduct): string => {
    if (!baseProduct?.uuid) {
      return ''
    }

    return baseProduct.uuid!
  },
)

export const selectIsAddressByForm = createSelector(
  [selectTestsFormStore],
  (location): string => {
    if (!location?.uuid) {
      return ''
    }

    return location.properties!.address!
  },
)

export const selectIsNameByForm = createSelector(
  [selectTestsFormStore],
  (location): string => {
    if (!location?.uuid) {
      return ''
    }

    return location!.name!
  },
)

export const selectAppointmentCheckTimeByForm = createSelector(
  [selectTestsFormTime],
  (time): boolean => {
    if (!time) {
      return false
    }

    return true
  },
)

export const selectAppointmentTimeByForm = createSelector(
  [selectTestsFormTime],
  (time): Date => {
    if (!time) {
      return new Date()
    }

    return time
  },
)

export const selectIsPhoneNumberRequiredByForm = createSelector(
  [selectTestsFormProduct],
  (product): boolean => {
    if (!product?.uuid) {
      return false
    }

    return product.phoneNumberRequired!
  },
)

export const selectFlow = createSelector(
  [selectTestsFlow],
  (flow): FlowType => {
    if (!flow) {
      return FlowType.FULL
    }

    return flow
  },
)

export const selectMinimumAgeByForm = createSelector(
  [selectTestsFormProduct],
  (product): number => {
    if (!product?.uuid) {
      return 0
    }

    return product.minimumAge!
  },
)

export const selectMinimumAgeByProduct = createSelector(
  [productServiceSelectors.getProductListState, selectTestsFormProduct],
  ({ data: products }, product): NullableNumber => {
    if (!product?.uuid || !products?.length) {
      return 0
    }

    const selectedProduct = products.find((p) => p.uuid === product.uuid)
    if (!selectedProduct) {
      return 0
    }

    return selectedProduct.minimumAge!
  },
)
