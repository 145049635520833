import { createSelector } from 'reselect'

import { User, UserRootState } from '@probatix/common/services/RTKServices/patients/index.model'

export const getUserListState = (state: UserRootState) => state.user.list

export const selectUsers = createSelector(
  [getUserListState],
  (usersState): User[] => {
    if (!usersState?.data) {
      return []
    }

    return usersState.data.slice().sort((a, b) => a.uuid!.localeCompare(b.uuid!))
  },
)

