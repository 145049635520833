import { combineReducers } from 'redux'

import availableDaysListService from './list/availableDaysListService'
import * as selectors from './list/availableDaysListService.selectors'
import * as actions from './list/availableDaysListService.actions'

export const availableDaysServiceActions = {
  ...actions,
}

export const availableDaysServiceSelectors = {
  ...selectors,
}

export const availableDaysServiceReducers = combineReducers({
  list: availableDaysListService,
})

