import { createSelector } from 'reselect'

import { VoucherSingleState, OrderRootState, PaymentTypes } from '../orderService.model'

export const getVoucherState = (state: OrderRootState): VoucherSingleState => state.order.voucher

export const selectIsFreeOrder = createSelector(
  [getVoucherState],
  ({ data }) => {
    if (!data) {
      return true
    }

    return !!data.paymentOptions.find((o) => PaymentTypes.FREE === o)
  },
)
