import { createGlobalStyle } from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'
import { MOBILE_WIDTH } from '@probatix/common/constants/design'
import 'bootstrap/dist/css/bootstrap.min.css'

export const GlobalStyled = createGlobalStyle`
  :root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.1);
    --rdp-background-color: ${COLORS.BLUE};
  }

  *, *:after, *:before {
    box-sizing: border-box;
  }

  html, body {
    min-height: 100vh;
    background: #FBFBFB;
    min-width: 320px;
    
    @media(max-width: ${MOBILE_WIDTH}px) {
      background: #FFF;
    }
  }

  #root {
    min-height: 100vh;
  }
  
  html, body, button {
    font-family: 'Roboto', sans-serif;
  }
  
  p {
    margin: 0;
    padding: 0;
  }
  
  a {
    color: ${COLORS.BLUE_LIGHT};
    text-decoration: none;
    transition: .2s;
    
    &:hover {
      color: ${COLORS.BLUE};
    }
  }
  
  [data-rsbs-overlay] {
    z-index: 1000;
  }

  [data-rsbs-content] {
    padding: 30px;
  }
  
  [data-rsbs-footer] {
    box-shadow: none;
    padding: 10px;
  }

  [class*="Snackbar_snackbar-wrapper"] {
    z-index: 100 !important;
  }
`
