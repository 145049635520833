import React, { PropsWithChildren } from 'react'

import Spinner from '@probatix/common/components/atoms/Spinner'

import { StyledPageWrapper } from './Page.styled'

interface PageProps {
  isLoading?: boolean
}

const Page: React.FC<PropsWithChildren<PageProps>> = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <StyledPageWrapper>
        <Spinner />
      </StyledPageWrapper>
    )
  }

  return (
    <StyledPageWrapper>
      {children}
    </StyledPageWrapper>
  )
}

export default Page
