import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AuthenticatedUser, AuthState } from './auth.model'

const initialState: AuthState = {
  data: null,
  errors: null,
  fetching: false,
  initialized: false,
  submitting: false,
}

export const slice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    loginFailed: (auth, action) => {
      auth.errors = action.payload
      auth.submitting = false
    },
    loginSubmitted: (auth) => {
      auth.errors = null
      auth.submitting = true
    },
    loginSuccess: (auth) => {
      auth.submitting = false
      auth.initialized = true
    },
    logout: () => initialState,
    meFailed: (auth, action) => {
      auth.fetching = false
      auth.errors = action.payload
    },
    meFetching: (auth) => {
      auth.errors = null
      auth.fetching = true
    },
    meSuccess: (auth, action) => {
      auth.fetching = false
      auth.data = action.payload
    },
    setAuthData: (auth, action: PayloadAction<AuthenticatedUser>) => {
      auth.data = action.payload
      auth.initialized = true
    },
    updateAuthData: (auth, action: PayloadAction<AuthenticatedUser>) => {
      auth.data = action.payload
    },
  },
})

export default slice.reducer
