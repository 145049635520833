
import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'
import { CHECKOUT_MAX_WIDTH } from '@probatix/common/constants/design'

export const StyledLocationBar = styled.div`
    background: ${COLORS.BLUE_LIGHT};
    color: ${COLORS.WHITE};
    border-radius: 0px;
    padding: 8px 11px;
`

export const StyledBaseProductBar = styled.div`
    background: ${COLORS.WHITE};
    border: 2px solid ${COLORS.BLUE_LIGHT};
    color: ${COLORS.WHITE};
    border-radius: 6px;
    max-width:${CHECKOUT_MAX_WIDTH};
    margin: auto;
`

export const StyledItemBar = styled.div`
    border-radius: 4px;
    padding: 8px 11px;
`

