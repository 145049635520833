import React from 'react'

import { NullableString } from '@probatix/common/models/global'

import { ImageStyled } from './Image.styled'

interface ImageProps {
  dataQA?: string,
  fill?: string;
  full?: boolean;
  height?: number;
  loading?: string,
  margin?: NullableString;
  objectFit?: string;
  objectPosition?: string;
  onError?: any;
  priority?: string,
  src: string;
  style?: any,
  width?: number;
}

const Image: React.FC<ImageProps> = ({
  dataQA,
  fill,
  full,
  height = 64,
  loading = "lazy",
  margin = 'auto',
  objectFit = 'contain',
  objectPosition = 'inherit',
  onError,
  priority = "low",
  src,
  style,
  width = 181,
}) => {
  const renderImage = (<ImageStyled
    $objectFit={objectFit}
    $objectPosition={objectPosition}
    alt="Image"
    data-qa={dataQA}
    fill={fill}
    full={full}
    height={height}
    loading={loading}
    margin={margin}
    priority={priority}
    src={src}
    style={style}
    width={width}
    onError={onError}
  />)

  return <picture>
    <source media="(max-width:699px)" srcSet={src} type="image/webp" />
    <source media="(max-width:640px)" srcSet={src} type="image/webp" />
    {renderImage}
  </picture>

}

export default Image
