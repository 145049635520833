import { getBasicSliceActions, ActionParams, getBasicCreateSingleAction } from '@probatix/common/utils/service'

import { slice } from './availabilitiesListService'

const URL = '/availabilities'

export const {
  availabilitiesListFailed,
  availabilitiesListFetching,
  availabilitiesListSuccess,
} = getBasicSliceActions(slice.actions)

export const getAvailabilities = (params: ActionParams<object> = {}): object => getBasicCreateSingleAction({
  customApiPrefix: '',
  onError: availabilitiesListFailed.type,
  onInit: availabilitiesListFetching.type,
  onSuccess: availabilitiesListSuccess.type,
  url: `${'/api/v2/checkout'}${URL}`,
  ...params,
})
