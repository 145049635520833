import { Nullable } from '@probatix/common/models/global'

import { BasicSingleInitialState } from '@probatix/common/utils/service'
import { Customer } from '@probatix/common/services/RTKServices/patients/index.model'
import { Patient } from '@probatix/common/services/toolkit/user/userService.model'

export const enum PaymentStatuses {
  CONFIRMED_PAY_LATER = 'confirmed_pay_later',
  FAILED = 'failed',
  OPEN = 'open',
  PAID = 'paid',
  PENDING = 'pending',
  REFUNDED = 'refunded',
}

export const enum OrderStatuses {
  CANCELLED = 'cancelled',
  DONE = 'done',
  DRAFT = 'draft',
  EXPIRED = 'expired',
  INCOMPLETE = 'incomplete',
  NEW = 'new',
}

export const enum PaymentTypes {
  FREE = 'FREE',
  OTHER = 'OTHER',
  PAYPAL = 'PAYPAL',
  STRIPE = 'STRIPE',
}

export const enum VoucherTypes {
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage',
}

export interface PatientEvent {
  name: string
  patientIndex: number
  testCode: string
}

export interface OrderConfirmationStatus {
  customerFirstName: string,
  grossPrice: number,
  ical: string,
  orderCode?: string,
  orderStatus: OrderStatuses,
  originalPrice: number,
  patientEvents: PatientEvent[],
  paymentOptions: PaymentTypes[],
  paymentStatus: PaymentStatuses,
  productName: string,
  redirectUrl?: string,
  selectedPaymentOption: keyof PaymentTypes,
  time: string,
  uuid?: string
}

export interface VoucherError {
  data?: any,
  detail: string
  title: string
  type: string
}

export interface VoucherData {
  code: string
  value: number
  voucherComment: string
  voucherType: VoucherTypes
}

export interface Voucher {
  grossPrice: number,
  originalPrice: number,
  paymentOptions: PaymentTypes[],
  voucher: Nullable<VoucherData>
  voucherAmount: number,
}

export interface OrderPayload {
  customer: Customer,
  grossPrice?: number,
  location: {
    uuid: string
  },
  overrideResponseData?: object,
  patientBookingInformations: Patient[],
  paymentMethod?: PaymentTypes,
  product: {
    urlKey?: string,
    uuid: string
  },
  quickOrderUuid?: string,
  selectedBookingTime: String,
  url?: string,
  voucher?: { code: string }
}

export interface Order {
  orderCode: string
  redirectUrl: string
  uuid: string
}

export interface VoucherSingleState extends BasicSingleInitialState {
  data: Nullable<Voucher>
}

export interface OrderSingleState extends BasicSingleInitialState {
  data: Nullable<Order>
}

export interface OrderState {
  order: OrderSingleState
  voucher: VoucherSingleState
}

export interface OrderRootState {
  order: OrderState
}
