import { createGlobalStyle } from 'styled-components'

export const IconsStyle = createGlobalStyle`

[vaul-drawer-direction="bottom"]::after {
  height: auto !important;
}

.icons {
  display: inline-block;
  font-family: "icons";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.icons-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.icons-xs {
  font-size: 0.75em;
}

.icons-sm {
  font-size: 0.875em;
}

.icons-1x {
  font-size: 1em;
}

.icons-2x {
  font-size: 2em;
}

.icons-3x {
  font-size: 3em;
}

.icons-4x {
  font-size: 4em;
}

.icons-5x {
  font-size: 5em;
}

.icons-6x {
  font-size: 6em;
}

.icons-7x {
  font-size: 7em;
}

.icons-8x {
  font-size: 8em;
}

.icons-9x {
  font-size: 9em;
}

.icons-10x {
  font-size: 10em;
}

.icons-fw {
  text-align: center;
  width: 1.25em;
}

.icons-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.icons-pull-left {
  float: left;
}

.icons-pull-right {
  float: right;
}

.icons.icons-pull-left {
  margin-right: 0.3em;
}

.icons.icons-pull-right {
  margin-left: 0.3em;
}


.icons-arrow-down::before {
  content: "\\ea01";
}

.icons-arrow-left::before {
  content: "\\ea02";
}

.icons-calendar::before {
  content: "\\ea03";
}

.icons-calendar-dark::before {
  content: "\\ea04";
}

.icons-check-circle::before {
  content: "\\ea05";
}

.icons-checkbox::before {
  content: "\\ea06";
}

.icons-checkbox-filled::before {
  content: "\\ea07";
}

.icons-checkmark::before {
  content: "\\ea08";
}

.icons-checkmark-location-bar::before {
  content: "\\ea09";
}

.icons-chevron-down::before {
  content: "\\ea0a";
}

.icons-chevron-left::before {
  content: "\\ea0b";
}

.icons-chevron-right::before {
  content: "\\ea0c";
}

.icons-clock::before {
  content: "\\ea0d";
}

.icons-close::before {
  content: "\\ea0e";
}

.icons-edit::before {
  content: "\\ea0f";
}

.icons-empty-star::before {
  content: "\\ea10";
}

.icons-eye::before {
  content: "\\ea11";
}

.icons-eye-invisible::before {
  content: "\\ea12";
}

.icons-home-icon::before {
  content: "\\ea13";
}

.icons-info::before {
  content: "\\ea14";
}

.icons-location-icon::before {
  content: "\\ea15";
}

.icons-marker::before {
  content: "\\ea16";
}

.icons-people::before {
  content: "\\ea17";
}

.icons-person::before {
  content: "\\ea18";
}

.icons-person-dark::before {
  content: "\\ea19";
}

.icons-plus::before {
  content: "\\ea1a";
}

.icons-plus-square::before {
  content: "\\ea1b";
}

.icons-signIn::before {
  content: "\\ea1c";
}

.icons-star::before {
  content: "\\ea1d";
}

.icons-test-tube::before {
  content: "\\ea1e";
}

.icons-user::before {
  content: "\\ea1f";
}

.icons-user-mobile::before {
  content: "\\ea20";
}

.icons-vial::before {
  content: "\\ea21";
}

`
