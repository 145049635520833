/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import color from 'color'
import styled from 'styled-components'

import React from 'react'

import { COLORS } from '@probatix/common/constants/colors'
import { MOBILE_WIDTH } from '@probatix/common/constants/design'

interface IStyledRadioGroupWrapper {
  containerPadding?: string
}

interface IStyledInput {
  myProbatix?: boolean
}

export const StyledRadioGroupWrapper = styled.div<IStyledRadioGroupWrapper>`
  display: grid;
  grid-row-gap: 2px;
  
  ${({ containerPadding }) => containerPadding && `
    @media(max-width: ${MOBILE_WIDTH}px) {
      padding-bottom: 80px;
    }
  `}
`

interface IStyledLabel {
  shortLabel?: boolean
}

interface IStyledItem {
  borderWhenActive?: boolean
  isActive?: boolean
  multiRows?: boolean
  noBackground?: boolean
  padding?: string
  rightComponentExists?: boolean
}

export const StyledItem = styled(({
  borderWhenDeactivated,
  isActive,
  multiRows, myProbatix, noBackground,
  noBorderWhenDeactivated, padding,
  rightComponentExists, ...rest }) => (<div {...rest} />)) <IStyledItem>`
  border-radius: 7px;
  padding: 20px;
  display: grid;
  grid-template-columns: 0.875rem auto;
  grid-column-gap: 13px;
  align-items: flex-start;
  border: 1px solid transparent;
  transition: .2s;
  cursor: pointer;

  :hover {
    background: ${color(COLORS.BLUE_LIGHT).alpha(0.06)};
  }

  ${({ myProbatix }) => myProbatix && `
    background: ${COLORS.GRAYISH_WHITE};
    margin-bottom: 5px;
    border: 1px solid rgba(0,0,0,0.07);
    padding: 2.125rem 15px 30px 26px;
    border-radius: 10px;
  `}
  
  ${({ isActive }) => isActive && `
    background: ${color(COLORS.BLUE_LIGHT).alpha(0.06)};
  `}
  
  ${({ noBorderWhenDeactivated }) => noBorderWhenDeactivated && `
    border-color: transparent;
  `}

  ${({ borderWhenActive, isActive }) => borderWhenActive && isActive && `
    border-color: ${COLORS.BLUE_LIGHT};
  `}  

  ${({ borderWhenActive, isActive, noBorderWhenDeactivated }) => borderWhenActive && isActive
    && noBorderWhenDeactivated
    && `
    border-color: ${COLORS.BLUE_LIGHT};
  `}  
  
  ${({ rightComponentExists }) => rightComponentExists && `
    grid-template-columns: 0.875rem 1fr auto;
    
    @media(max-width: 480px) {
      grid-template-columns: 0.875rem auto;
      grid-template-rows: auto auto;
      grid-row-gap: 8px;
      
      *:nth-of-type(2) {
        grid-column-start: 2;
        grid-row-start: 2;
        justify-content: flex-start;
      }
    }
  `}  

  ${({ multiRows }) => multiRows && `
    grid-template-columns: 0.875rem auto;
    grid-template-rows: auto auto;
    grid-row-gap: 8px;
    
    *:nth-of-type(2) {
      grid-column-start: 2;
      grid-row-start: 2;
      justify-content: flex-start;
    }
  `}

@media(max-width: 520px) {
  padding: 20px 15px;
}

  ${({ padding }) => padding && `
    padding: ${padding};

     @media(max-width: 520px) {
      padding:${padding};
    }
  `}
  
  ${({ noBackground }) => noBackground && `
    background: white !important;

    &:hover {
      background: white !important;
    }
  `}

`

export const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.BLACK};
  word-break: break-word;
`

export const StyledLabel = styled.label<IStyledLabel>`
padding-top: 20px;

  ${({ shortLabel }) => shortLabel && `
    padding-top: 10px;
  `}
`

export const StyledSubTitle = styled.div`
font-size: 0.875rem;
color: ${COLORS.BLACK};
margin-top: 5px;
`

export const StyledInput = styled.input<IStyledInput>`
width: 1rem;
height: 1rem;
border-radius: 50%;
border: 1px solid ${COLORS.MAIN_GRAY};
appearance: none;
cursor: pointer;
position: relative;
transition: .2s;
top: calc(50% - 0.5rem);

  
  &:checked {
  border: 1px solid ${COLORS.BLUE_LIGHT};
    
    &:before {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    border: 1px solid ${COLORS.BLUE_LIGHT};
    background: ${color(COLORS.BLUE_LIGHT).alpha(0.87)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
  }
}
`

export const StyledRight = styled.div`
display: flex;
justify-content: flex-end;
height: 100%;
`
