import { combineReducers } from 'redux'

import { AvailabilitiesRootState } from '@probatix/common/services/toolkit/availabilities/availabilitiesService.model'
import { AvailableDaysRootState } from '@probatix/common/services/toolkit/availableDays/availableDaysService.model'
import { TestsServiceRootState } from '@probatix/common/services/toolkit/tests/testsService.model'
import { OrderRootState } from '@probatix/common/services/toolkit/order/orderService.model'
import { AuthRootState } from '@probatix/common/services/toolkit/auth/auth.model'
import { TranslationsRootState } from '@probatix/common/services/toolkit/translations/translationsService.model'

import { availabilitiesServiceReducers } from '@probatix/common/services/toolkit/availabilities'
import { availableDaysServiceReducers } from '@probatix/common/services/toolkit/availableDays'
import { testsServiceReducers } from '@probatix/common/services/toolkit/tests'
import { modalServiceReducers, ModalRootState } from '@probatix/common/services/toolkit/modal'
import { orderServiceReducers } from '@probatix/common/services/toolkit/order'
import { authServiceReducers } from '@probatix/common/services/toolkit/auth'
import { translationsServiceReducers } from '@probatix/common/services/toolkit/translations'

import { locationsApi } from '@probatix/common/services/RTKServices/locations'

import { AppServiceRootState } from 'services/app/appService.model'
import { appServiceReducers } from 'services/app'

export interface RootState extends AvailableDaysRootState,
  AuthRootState,
  AppServiceRootState,
  AvailabilitiesRootState,
  TestsServiceRootState,
  OrderRootState,
  ModalRootState,
  TranslationsRootState { }

export default combineReducers({
  app: appServiceReducers,
  auth: authServiceReducers,
  availabilities: availabilitiesServiceReducers,
  availableDays: availableDaysServiceReducers,
  modal: modalServiceReducers,
  order: orderServiceReducers,
  tests: testsServiceReducers,
  translations: translationsServiceReducers,
  [locationsApi.reducerPath]: locationsApi.reducer,

})

