import { motion, AnimatePresence } from 'framer-motion'
import { Property } from 'csstype'
import ResizeObserver from 'resize-observer-polyfill'

import React, { PropsWithChildren, useEffect } from 'react'
import { useLayer, Arrow } from 'react-laag'
import { PlacementType } from 'react-laag/dist/PlacementType'
import { Options } from 'react-laag/dist/types'

import { COLORS } from '@probatix/common/constants/colors'

import { StyledButton, StyledBox, Z_INDEX_POPOVER } from './Popover.styled'

interface PopoverProps {
  button: React.ReactNode
  buttonFull?: boolean
  disableArrow?: boolean
  /**
   * This param disable automatically disabling the popover after clicking in something inside popover
   */
  disableCloseInside?: boolean
  disabled?: boolean
  isOpen?: boolean
  margin?: Property.Margin
  onClick?: (value: boolean) => void
  onOutsideClick?: (value: boolean) => void
  options?: Options | {}
  placement?: PlacementType
  renderLayerWrapper?: (content: React.ReactNode) => React.ReactElement
  zIndex?: number
}

let blockade = false

const Popover: React.FC<PropsWithChildren<PopoverProps>> = ({
  button,
  buttonFull,
  children,
  disableArrow,
  disableCloseInside,
  disabled,
  isOpen: isOpenExternal,
  margin,
  onClick,
  onOutsideClick,
  options,
  placement = 'bottom-center',
  renderLayerWrapper,
  zIndex,
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false)
  const finalIsOpen = isOpenExternal !== undefined ? isOpenExternal : isOpen

  useEffect(() => {
    const element = document.getElementById('layers')

    if (null !== element && zIndex && (zIndex > +element.style.zIndex || Z_INDEX_POPOVER)) {
      element.style.zIndex = `${zIndex}`
      blockade = true

      setTimeout(() => {
        blockade = false
      }, 200)
    }
  }, [zIndex, isOpen, isOpenExternal])

  useEffect(() => {
    const value = isOpenExternal !== zIndex ? false === isOpenExternal : !isOpen
    const element = document.getElementById('layers')

    if (value) {
      setTimeout(() => {
        if (!blockade && null !== element) {
          element.style.zIndex = `${Z_INDEX_POPOVER}`
        }
      }, 100)
    }
  }, [isOpenExternal, isOpen])

  const handleClickButton = (clickedButton) => {
    if (disabled) {
      return null
    }

    if (!clickedButton && disableCloseInside && finalIsOpen) {
      return null
    }

    if (isOpenExternal !== undefined && onClick) {
      onClick(!isOpenExternal)
    }

    return setOpen(!isOpen)
  }

  const handleOutsideClick = () => {
    if (isOpenExternal === undefined) {
      setOpen(false)
    }

    onOutsideClick?.(false)
  }

  const handleDisappear = () => {
    if (isOpenExternal === undefined) {
      setOpen(false)
    }

    onClick?.(false)
  }

  const { arrowProps, layerProps, renderLayer, triggerProps } = useLayer({
    ResizeObserver,
    arrowOffset: 20,
    auto: true,
    containerOffset: 25,
    isOpen: finalIsOpen,
    onDisappear: handleDisappear,
    onOutsideClick: handleOutsideClick,
    placement,
    triggerOffset: 20,
    ...(options || {}),
  })

  const renderContent = () => {
    const content = (
      <StyledBox
        margin={margin}
        zIndex={zIndex}
        onClick={() => handleClickButton(false)}
      >
        {children}
        {!disableArrow && (
          <Arrow
            backgroundColor={COLORS.WHITE}
            borderColor={COLORS.WHITE}
            borderWidth={1}
            roundness={0.5}
            size={12}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            {...arrowProps}
          />
        )}
      </StyledBox>
    )

    if (renderLayerWrapper) {
      return renderLayerWrapper(content)
    }

    return content
  }

  return (
    <React.Fragment>
      <StyledButton buttonFull={buttonFull} {...triggerProps} onClick={() => handleClickButton(true)}>
        {button}
      </StyledButton>
      {renderLayer(
        // @ts-ignore
        <AnimatePresence>
          {finalIsOpen && (
            <motion.div
              {...layerProps}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              initial={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.2 }}
            >
              {renderContent()}
            </motion.div>
          )}
        </AnimatePresence>,
      )}
    </React.Fragment>
  )
}

export default Popover
