
import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'

export const StyledIconButton = styled.a`
    width: 100%;
    margin: auto;
    color: ${COLORS.BLACK};

    &:hover {
        color: ${COLORS.DARK_GRAY_HOVER};
    }
`
