import { Property } from 'csstype'
import styled from 'styled-components'

interface IImageStyled {
  $objectFit?: Property.ObjectFit,
  $objectPosition?: Property.ObjectPosition,
  full?: boolean,
  margin?: string
}

export const ImageStyled = styled.img<IImageStyled>`  
  display: block;
  
  ${({ $objectFit }) => $objectFit && `
    object-fit: ${$objectFit};
  `}

  ${({ $objectPosition }) => $objectPosition && `
    object-position: ${$objectPosition};
  `}
  
  ${({ margin }) => margin && `
    margin: ${margin};
  `}
  
  ${({ full }) => full && `
    width: 100%;
  `}
`
