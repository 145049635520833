import { useEffect, useState } from 'react'

const useDefaultImg = ({ dummyImg, path }: { dummyImg?: any, path?: any; }) => {
  const [selectedImage, setSelectedImg] = useState(path)

  useEffect(() => {
    const fetchImg = () => {
      const img = new Image()

      img.onload = () => {
        setSelectedImg(path ?? dummyImg)
      }

      img.onerror = () => {
        setSelectedImg(dummyImg)
      }

      img.src = path
    }

    fetchImg()
  }, [path])

  return selectedImage
}

export default useDefaultImg
