import { createSelector } from 'reselect'

import { Nullable } from '@probatix/common/models/global'

import { Customer, User } from '@probatix/common/services/RTKServices/patients/index.model'

import { AuthenticatedUser, AuthRootState, AuthState } from './auth.model'

export const getAuthState = (state: AuthRootState): AuthState => state.auth

export const selectAuthData = createSelector(
  [getAuthState],
  (state) => state.data,
)

export const selectIsAuthenticated = createSelector(
  [selectAuthData],
  (authData): boolean => !!authData,
)

export const selectAuthErrors = createSelector(
  [getAuthState],
  (state) => state.errors,
)

export const getUser = (data: AuthenticatedUser): Nullable<User> => data

export const selectMainUser = createSelector(
  [getAuthState],
  (state): Nullable<Customer> => state.data,
)

export const selectUserEmail = createSelector(
  [getAuthState],
  (state): string => {
    try {
      if (!state.data!) {
        return ''
      }

      return state.data?.email!
    } catch (error) {
      return ''
    }
  },
)
