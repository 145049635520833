export const ACCORDION_TITLE: string = '1rem'
export const ACCORDION_TITLE_WEIGHT: number = 700
export const ACCORDION_DESCRIPTION: string = '0.95rem'
export const ACCORDION_DESCRIPTION_WEIGHT: number = 400
export const CONTAINER_WIDTH: number = 1320
export const PAGE_WIDTH: number = 1520
export const MENU_HEIGHT: number = 80
export const MOBILE_MAP: string = '43vh'
export const MOBILE_MAP_REST: string = '57vh'
export const DESKTOP_CHECKOUT_TOP: string = '57vh'
export const DESKTOP_CHECKOUT_BOTTOM: string = '57vh'
export const CHECKOUT_LOGO_HEIGHT: number = 80
export const CHECKOUT_IMG_HEIGHT: number = 400
export const CHECKOUT_FORM_MARGIN_BOTTOM: string = '40px'
export const CHECKOUT_MAX_NUMBER: number = 459
export const CHECKOUT_MAX_WIDTH: string = `${CHECKOUT_MAX_NUMBER}px`
export const CHECKOUT_FOOTER_HEIGHT: string = '80px'
export const QUICK_CHECK_MAX_WIDTH: string = '459px'
export const MOBILE_WIDTH = 480
export const MAX_SMALL_DESKTOP: number = 880
export const MAX_DEFAULT_DESKTOP: number = 1200
export const MAX_LARGE_DESKTOP: number = 1600
export const MAX_X_LARGE_DESKTOP: number = 2000
export const MIN_BIG_MOBILE_DEVICE: number = 500
export const DEFAULT_MOBILE_MAX_WIDTH = `(max-width: ${MOBILE_WIDTH}px)`
export const SMALL_DESKTOP_MAX_WIDTH = `(max-width: ${MAX_SMALL_DESKTOP}px)`
export const SMALL_DESKTOP_MIN_WIDTH = `(max-width: ${MIN_BIG_MOBILE_DEVICE}px)`
export const BIG_DEVICE_MIN_WIDTH = `(min-width: ${MIN_BIG_MOBILE_DEVICE}px)`
export const RESULT_APPOINTMENT_CARD_TITLE_DESKTOP_SIZE: string = '1.125rem'
export const RESULT_APPOINTMENT_CARD_TITLE_MOBILE_SIZE: string = '0.875rem'
export const FORM_FONT_SIZE: string = '0.775rem'
export const FORM_FONT_WEIGHT: number = 400
export const LOAD_PAGE_MS_JWT: number = 700
export const LINK_SIZE: string = '0.75rem'
export const TITLE_H1_SIZE: string = '1.5rem'
export const TITLE_H2_SIZE: string = '1.25rem'
export const TITLE_H3_SIZE: string = '1.025rem'
export const TITLE_H1_WEIGHT: number = 700
export const FORM_SUBTITLE_WEIGHT: number = 600
export const FORM_MAX_WIDTH: number = 450
export const FORM_MULTIPLE_COLUMN_MAX_WIDTH: number = 555
export const FORM_SUBTITLE_SIZE: string = '0.875rem'
export const SUBTITLE_SIZE: string = '1rem'
export const FORM_STANDARD_TEXT_SIZE: string = '0.775rem'
export const FORM_STANDARD_H2_TEXT_SIZE: string = '0.75rem'
export const FORM_SMALL_TEXT_SIZE: string = '0.675rem'
export const NUMBER_OF_ITEMS_PER_PAGE: number = 30
export const INPUT_HEIGHT_DESKTOP: string = '2.2875rem'
export const INPUT_HEIGHT_MOBILE: string = '2rem'
export const DESKTOP_LOGGEDIN_HEADER: string = '60px'
export const MOBILE_LOGGEDIN_HEADER: string = '60px'
export const MOBILE_LOGGEDIN_FOOTER: string = '80px'
export const MOBILE_TOTAL_LOGGEDIN: string = `calc(${MOBILE_LOGGEDIN_HEADER} + ${MOBILE_LOGGEDIN_FOOTER})`
