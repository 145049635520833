import add from 'date-fns/add'
import Cookies from 'js-cookie'

import { PayloadAction } from '@reduxjs/toolkit'

import { ActionParams } from '@probatix/common/utils/service'

import { AUTH_SUCCESSFUL_KEY } from '@probatix/common/services/toolkit/auth/auth.constants'

import { authServiceActions } from '@probatix/common/services/toolkit/auth'
import { getDomain } from '@probatix/common/utils/url'

type Action = PayloadAction<ActionParams<object>>

const loginMiddleware = (withSubdomain: boolean = true) => () => (next) => async (action: Action) => {
  if (!authServiceActions.loginSuccess.match(action)) {
    return next(action)
  }

  // NOTE: middleware used for all of the probatix services except login to ensure the single sign on
  Cookies.set(AUTH_SUCCESSFUL_KEY, JSON.stringify(new Date()), {
    domain: `.${getDomain(withSubdomain)}`,
    expires: add(new Date(), { days: 7 }),
  })

  return next(action)
}

export default loginMiddleware
