import { createSelector } from 'reselect'

import { AvailabilitiesListState, AvailabilitiesRootState } from '../availabilitiesService.model'

export const getAvailabilitiesState = (state: AvailabilitiesRootState) => state.availabilities

export const getAvailabilitiesListState = (state: AvailabilitiesRootState) => state.availabilities.list

export const selectAvailabilitiesList = createSelector(
  [getAvailabilitiesListState],
  (listState): AvailabilitiesListState => ({
    data: listState.data,
    errors: listState.errors,
    fetching: listState.fetching,
    initialized: listState.initialized,
  }),
)
