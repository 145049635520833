import { Customer } from '@probatix/common/services/RTKServices/patients/index.model'
import { isBillingAddressSet } from '@probatix/common/services/toolkit/user/userService.helpers'
import {
  OrderConfirmationStatus,
  OrderStatuses,
  PaymentStatuses,
} from '@probatix/common/services/toolkit/order/orderService.model'
import { returnDateOfBirth } from '@probatix/common/services/services.helpers'

export const getBillingAddress = (main: Customer) => {
  const isBillingSet = isBillingAddressSet(main)

  return {
    billingAddressCity: isBillingSet ? main.billingAddressCity : main.addressCity,
    billingAddressCountry: isBillingSet ? main.billingAddressCountry : main.addressCountry,
    billingAddressStreet: isBillingSet ? main.billingAddressStreet : main.addressStreetAndHouseNumber,
    billingAddressZipCode: isBillingSet ? main.billingAddressZipCode : main.addressZipCode,
    ...(isBillingSet && { company: main.company }),
  }
}

export const transformCustomerToOrderPayload = (main: Customer, includeBilling: boolean = false): Customer => {
  const payload: Customer = {
    addressCity: main.addressCity,
    addressCountry: main.addressCountry,
    addressStreetAndHouseNumber: main.addressStreetAndHouseNumber,
    addressZipCode: main.addressZipCode,
    dateOfBirth: main?.dateOfBirth && returnDateOfBirth({
      dateOfBirth: main.dateOfBirth,
      formatDate: 'yyyy-MM-dd',
    }),
    email: main.email,
    firstName: main.firstName,
    gender: main.gender,
    healthInsuranceCompanyNumber: main.healthInsuranceCompanyNumber,
    healthInsurancePolicyNumber: main.healthInsurancePolicyNumber,
    lastName: main.lastName,
    personalIdNumber: main.personalIdNumber,
    phoneNumber: main.phoneNumber,
  }

  if (includeBilling) {
    const billing = getBillingAddress(main)

    return {
      ...payload,
      ...billing,
    }
  }

  return payload
}

export const isOrderPending = (confirmation: OrderConfirmationStatus): boolean => {
  const { orderStatus, paymentStatus } = confirmation

  if (0 <= [OrderStatuses.CANCELLED, OrderStatuses.DONE].indexOf(orderStatus)) {
    return false
  }

  if (0 <= [PaymentStatuses.FAILED, PaymentStatuses.CONFIRMED_PAY_LATER, PaymentStatuses.PAID, PaymentStatuses.REFUNDED]
    .indexOf(paymentStatus)
  ) {
    return false
  }

  return true
}

export const isOrderSuccessful = (confirmation: OrderConfirmationStatus): boolean => {
  const { orderStatus, paymentStatus } = confirmation

  if (0 <= [OrderStatuses.NEW, OrderStatuses.DONE].indexOf(orderStatus)
    && 0 <= [PaymentStatuses.CONFIRMED_PAY_LATER, PaymentStatuses.PAID, PaymentStatuses.REFUNDED]
      .indexOf(paymentStatus)
  ) {
    return true
  }

  return false
}

export const isOrderFailed = (confirmation: OrderConfirmationStatus): boolean => {
  const { orderStatus, paymentStatus } = confirmation

  return PaymentStatuses.FAILED === paymentStatus
    || 0 <= [OrderStatuses.CANCELLED, OrderStatuses.EXPIRED].indexOf(orderStatus)
}
