import React from 'react'

import { SpaceProps } from './Space.model'
import { StyledSpace } from './Space.styled'

const Space: React.FC<SpaceProps> = ({
  inline,
  margin,
  padding,
  space,
}) => (
  <StyledSpace
    inline={inline}
    margin={margin}
    padding={padding}
    space={space}
  />
)

export default Space
