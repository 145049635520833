import color from 'color'
import styled, { keyframes, css } from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'

import { COLORS } from '@probatix/common/constants/colors'

const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const contentShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(.96);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

export const StyledDialogOverlay = styled(Dialog.Overlay)`
  ${css`
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: ${color(COLORS.BLACK).alpha(0.6)};
    inset: 0;
    position: fixed;
    z-index: 200;
  `}
`

export const StyledDialogCrossButton = styled.div`
  float: right;
`

export const StyledDialogContent = styled(Dialog.Content)`
  ${css`
    &:focus {
      outline: none;
    }
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: ${COLORS.WHITE};
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    left: 50%;
    max-height: 85vh;
    max-width: 450px;
    padding: 25px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    overflow: scroll;
    z-index: 201;
  `}
`

export const Flex = styled('div', { display: 'flex' })

export const Button = styled('button', {
  alignItems: 'center',
  all: 'unset',
  borderRadius: 4,
  defaultVariants: {
    variant: 'violet',
  },
  display: 'inline-flex',
  fontSize: 15,
  fontWeight: 500,
  height: 35,
  justifyContent: 'center',
  lineHeight: 1,
  padding: '0 15px',
  variants: {
    variant: {
      green: {
        '&:focus': { boxShadow: `0 0 0 2px green` },
        '&:hover': { backgroundColor: "green" },
        backgroundColor: "green",
        color: "green",
      },
      violet: {
        '&:focus': { boxShadow: `0 0 0 2px black` },
        '&:hover': { backgroundColor: "blue" },
        backgroundColor: 'white',
        boxShadow: `0 2px 10px black`,
        color: "pink",
      },
    },
  },
})

export const IconButton = styled('button', {
  '&:focus': { boxShadow: `0 0 0 2px pink` },
  '&:hover': { backgroundColor: "pink" },
  alignItems: 'center',
  all: 'unset',
  borderRadius: '100%',
  color: "pink",
  display: 'inline-flex',
  fontFamily: 'inherit',
  height: 25,
  justifyContent: 'center',
  position: 'absolute',
  right: 10,
  top: 10,
  width: 25,
})

export const Fieldset = styled('fieldset', {
  alignItems: 'center',
  all: 'unset',
  display: 'flex',
  gap: 20,
  marginBottom: 15,
})

export const Label = styled('label', {
  color: "pink",
  fontSize: 15,
  textAlign: 'right',
  width: 90,
})

export const Input = styled('input', {
  '&:focus': { boxShadow: `0 0 0 2px red` },
  alignItems: 'center',
  all: 'unset',
  borderRadius: 4,
  boxShadow: `0 0 0 1px black`,
  color: "pink",
  display: 'inline-flex',
  flex: '1',
  fontSize: 15,
  height: 35,
  justifyContent: 'center',
  lineHeight: 1,
  padding: '0 10px',
  width: '100%',
})
