import styled from 'styled-components'

interface IStyledColumn {
  padding?: string
}

export const StyledColumn = styled.div<IStyledColumn>`
  flex: 1 1 0;
  width: 100%;
  padding: ${(props) => (props.padding)};
`
