import translationsService from './translationsService'
import * as selectors from './translationsService.selectors'
import * as actions from './translationsService.actions'

export const translationsServiceActions = {
  ...actions,
}

export const translationsServiceSelectors = {
  ...selectors,
}

export const translationsServiceReducers = translationsService
