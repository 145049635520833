import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SingleTranslation, TranslationsState } from './translationsService.model'

const initialState: TranslationsState = {
  data: null,
  errors: false,
  fetching: false,
}

export const slice = createSlice({
  initialState,
  name: 'list',
  reducers: {
    translationsFailed: {
      prepare(payload: any, locale: string) {
        return { meta: { locale }, payload }
      },
      reducer(
        state: TranslationsState,
        action: PayloadAction<SingleTranslation, string, { locale: string }>,
      ) {
        const { locale } = action.meta

        state.data = {
          ...state.data,
          [locale]: {
            data: { ...state.data?.[locale]?.data },
            errors: true,
            fetching: false,
            initialized: true,
          },
        }
        state.errors = true
        state.fetching = false
      },
    },
    translationsFetching: {
      prepare(payload: any, locale: string) {
        return { meta: { locale }, payload }
      },
      reducer(
        state: TranslationsState,
        action: PayloadAction<SingleTranslation, string, { locale: string; }>,
      ) {
        const { locale } = action.meta

        state.data = {
          ...state.data || {},
          [locale]: {
            data: { ...state.data?.[locale]?.data },
            fetching: true,
            initialized: true,
          },
        }
        state.errors = false
        state.fetching = true
      },
    },
    translationsSuccess: {
      prepare(payload: SingleTranslation, locale: string) {
        return { meta: { locale }, payload }
      },
      reducer(
        state: TranslationsState,
        action: PayloadAction<SingleTranslation, string, { locale: string }>,
      ) {
        const { locale } = action.meta

        state.data = {
          ...state.data,
          [locale]: {
            data: {
              ...state.data?.[locale]?.data,
              ...action.payload,
            },
            fetching: false,
            initialized: true,
          },
        }
        state.fetching = false
      },
    },
  },
})

export default slice.reducer
