import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { fr, enGB as en, de } from 'date-fns/locale'

import commonTranslations from '@probatix/common/translations/frontend-common-translations.json'

import Trans from './Trans'

const { de: deLang, en: enLang, fr: frLang } = commonTranslations

export const LANGUAGES = {
  de: deLang, en: enLang, fr: frLang,
}
export const LOCALES = { de, en, fr }
export const getCurrentLocale = () => LOCALES[i18n.language || 'de']

export const init = (resources: any, fallbackLng: string = 'de', options = {}) => {
  i18n.use(initReactI18next)
    .init({
      fallbackLng,
      react: {
        transKeepBasicHtmlNodesFor: ['br', 'b', 'strong', 'i', 'u'],
        transSupportBasicHtmlNodes: false,
        transWrapTextNodes: '',
      },
      resources,
      ...options,
    })

  return i18n
}

// NOTE: we need i18n for storybook
if ('storybook' === process.env.NODE_ENV) {
  init(LANGUAGES)
}

export { Trans }
export default i18n
