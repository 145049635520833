import styled from 'styled-components'

import { CursorTypes } from '@probatix/common/constants/css'

interface StyledContainerProps {
  cursor?: CursorTypes
  onClick?: () => void
}

export const StyledContainer = styled.div<StyledContainerProps>`
  font-size: 0;
  display: flex;

  ${({ onClick }) => onClick && `
    cursor: pointer;
  `}
  
  ${({ cursor }) => cursor && `
    cursor: ${cursor};
  `}
`
