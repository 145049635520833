import styled from 'styled-components'

export const StyledIconLabel = styled.div`
  display: grid;
  grid-template-columns: 18px auto;
  grid-gap: 8px;
  margin-bottom: 15px;
  align-items: center;
`

export const StyledConfirmationHeader = styled.div`
  display: inline-flex;
  
  p {
    width: auto;
  }
`
