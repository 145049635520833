import format from 'date-fns/format'

import { createSelector } from 'reselect'

import { Nullable } from '@probatix/common/models/global'

import { ApiError, formatApiErrors } from '@probatix/common/utils/url'

import { testsServiceSelectors } from '@probatix/common/services/toolkit/tests'
import { Patient } from '@probatix/common/services/toolkit/user/userService.model'
import { returnDateOfBirth } from '@probatix/common/services/services.helpers'

import { OrderPayload, OrderRootState, OrderSingleState } from '../orderService.model'
import { transformCustomerToOrderPayload } from '../orderService.helpers'

export const getOrderState = (state: OrderRootState): OrderSingleState => state.order.order

export const selectOrderPayload = createSelector(
  [testsServiceSelectors.selectTestsFormState],
  (testsForm): Nullable<OrderPayload> => {
    if (
      !testsForm.location
      || !testsForm.product
      || !testsForm.time
      || !testsForm.person
    ) {
      return null
    }

    const main = testsForm.person
    const patientBookingInformations: Patient[] = [{
      addressCity: main?.addressCity,
      addressCountry: main?.addressCountry,
      addressStreetAndHouseNumber: main?.addressStreetAndHouseNumber,
      addressZipCode: main?.addressZipCode,
      dateOfBirth: main?.dateOfBirth ? returnDateOfBirth({
        dateOfBirth: main?.dateOfBirth,
        formatDate: 'yyyy-MM-dd',
      }) : '',
      email: main?.email,
      firstName: main.firstName,
      gender: main.gender,
      healthInsuranceCompanyNumber: main.healthInsuranceCompanyNumber,
      healthInsurancePolicyNumber: main.healthInsurancePolicyNumber,
      lastName: main.lastName,
      myProbatixUserUuid: undefined,
      personalIdNumber: main.personalIdNumber,
      phoneNumber: main.phoneNumber,
    }]

    const includeBilling: boolean = (!!main.billingAddressStreet
      && !!main.billingAddressCity
      && !!main.billingAddressZipCode
      && !!main.billingAddressCountry
    )
    const customer = transformCustomerToOrderPayload(main, includeBilling)

    return {
      customer,
      location: { uuid: testsForm.location.uuid },
      patientBookingInformations,
      product: { uuid: testsForm.product.uuid },
      selectedBookingTime: format(new Date(testsForm.time), 'yyyy-MM-dd HH:mm:ss'),
    }
  },
)

export const selectOrderErrors = createSelector(
  [getOrderState],
  (state): string[] => {
    const { errors } = state

    if (!errors) {
      return []
    }

    return formatApiErrors(errors as ApiError)
  },
)
