import React, { ReactNode } from 'react'
import DOMPurify from 'dompurify'
import { Trans as ReactTrans, useTranslation } from 'react-i18next'

export interface TransProps {
  components?: ReactNode[] | any;
  k: string;
  params?: {};
}

const Trans: React.FC<TransProps> = ({ k, params = {}, components }) => {
  const { i18n } = useTranslation()

  if (components) {
    return <ReactTrans components={components} i18nKey={DOMPurify.sanitize(k)} values={params} />
  }
   
  return <React.Fragment>{i18n.t(DOMPurify.sanitize(k), params) as ReactNode}</React.Fragment>
}

export default Trans
