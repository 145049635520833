import React from 'react'

import { StyledBreadcrumbItem, StyledBreadcrumbItemWrapper } from './Breadcrumb.styled'

export interface BreadcrumbItemProps {
  dataTestId?: string
  isActive: boolean
  label: string
  to: string
}

const BreadcrumbItem: any = ({ dataTestId, isActive, label, to }) => (
  <StyledBreadcrumbItemWrapper data-testid={dataTestId}>
    <StyledBreadcrumbItem isActive={isActive} to={to}>
      {label}
    </StyledBreadcrumbItem>
  </StyledBreadcrumbItemWrapper>
)

export default BreadcrumbItem
