import React from 'react'
import DOMPurify from 'dompurify'
import { TransProps } from '@probatix/common/translations/Trans'

import { Trans as BasicTrans } from '@probatix/common/translations'

const Trans: React.FC<TransProps> = ({ k, ...rest }) => {
  const formattedKey = k.includes(':') ? k.split(':').join('.') : k

  return <BasicTrans k={DOMPurify.sanitize(formattedKey)} {...rest} />
}

export default Trans
