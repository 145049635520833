import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { t } from 'i18next'
import color from 'color'
import { Trans } from 'translations'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import { COLORS } from '@probatix/common/constants/colors'
import { ICharObservations } from '@probatix/common/services/RTKServices/testResult/testResultService.model'
import { useFormattedDateTime } from '@probatix/common/hooks'
import { TITLE_H1_WEIGHT, TITLE_H2_SIZE } from '@probatix/common/constants/design'

import { DECISIONS } from '@probatix/common/constants/decisions'
import arrowDownSVG from '@probatix/common/assets/icons/arrow-down.svg'

import Image from '../Image'
import { generateAssetUrl } from '../TextField/TextField.helpers'
import { StyledArrow, StyledSelectWrapper } from '../Select/Select.styled'
import { Card, CardContent, CardHeader, Select } from './BarChart.styled'
import Typography from '../Typography'

const fillBetweenPlugin = {
  beforeDraw: (chart, args, options) => {
    const { chartArea, ctx, scales } = chart

    if (!scales.y) return

    const isYMinInvalid = !options.yMin && 0 !== options?.yMin
    const isYMaxInvalid = !options.yMax && 0 !== options?.yMax

    if (isYMinInvalid || isYMaxInvalid) return

    const min = scales.y.getPixelForValue(options.yMin)
    const max = scales.y.getPixelForValue(options.yMax)

    ctx.save()
    ctx.fillStyle = color(COLORS.GREEN_TEMPO_TEAL).alpha(0.2)

    ctx.fillRect(chartArea.left, min, chartArea.right - chartArea.left, max - min)

    ctx.restore()
  },
  id: 'fillBetween',
}

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels, fillBetweenPlugin)

const colorScheme = {
  optimal: COLORS.GREEN_TEMPO_TEAL,
  others: COLORS.YELLOW,
}

interface Params {
  callBackSelectedMarker: (val) => void;
  data: ICharObservations[];
  productName: string;
  yMax: Number | undefined;
  yMin: Number | undefined;
}

const BarChart: React.FC<Params> = ({ callBackSelectedMarker, data, productName, yMax, yMin }) => {

  const [timeRange, setTimeRange] = useState('1y')
  const { formattedLocaleDateWithoutDayOfWeek2DigitYear } = useFormattedDateTime()

  const filteredData = data.filter((item: ICharObservations) => {
    const itemDate = new Date(item!.testTime!)
    const now = new Date()
    switch (timeRange) {
      case '1m':
        return itemDate >= new Date(now.setMonth(now.getMonth() - 1))
      case '3m':
        return itemDate >= new Date(now.setMonth(now.getMonth() - 3))
      case '6m':
        return itemDate >= new Date(now.setMonth(now.getMonth() - 6))
      case '1y':
      default:
        return true
    }
  })

  const getBarColor = (decision: string) => DECISIONS.OPTIMAL.includes(decision)
    ? colorScheme.optimal
    : colorScheme.others

  const chartData = {
    datasets: [{
      backgroundColor: filteredData.map((item) => getBarColor(item!.decision!)),
      borderRadius: 5,
      data: filteredData.map((item) => parseFloat(item!.value!)),
      label: `${t(productName)} ${t('global:level')}`,
      maxBarThickness: 60,
    }],
    labels: filteredData.map((item) => formattedLocaleDateWithoutDayOfWeek2DigitYear(item!.testTime!)),
  }

  const unit = data?.[0]?.unit ?? ''
  const boundaries = data?.[0]?.range?.boundaries ?? [0]
  const last = Number(boundaries.at(-1))
  const maxValue = (Math.max(...data.map((obj) => Number(obj.value))) < last)
    ? last
    : Math.max(...data.map((obj) => Number(obj.value)))

  const extraSpace = 4

  const options = {
    onClick: (event, elements) => {
      if (0 < elements.length) {
        const firstElement = elements[0]
        const index = firstElement.index

        callBackSelectedMarker(index)
      }
    },
    plugins: {
      datalabels: {
        align: "center",
        anchor: "end",
        backgroundColor: function (context) {
          return context.dataset.backgroundColor
        },
        borderColor: "white",
        borderRadius: 50,
        borderWidth: 2,
        color: "white",
        display: true,
        font: {
          size: 10,
          weight: "bold",
        },
        formatter: (value) => `${value}`,
        margin: { bottom: 0, left: 0, right: 0, top: 0 },
        padding: { bottom: 8, left: 10, right: 10, top: 8 },
      },
      fillBetween: {
        yMax: -1 === Number(yMax) ? Number(maxValue) + extraSpace : Number(yMax),
        yMin: Number(yMin),
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const value = context.raw
            const decision = filteredData[context.dataIndex].decision
            const localeDecision = t(`decision:${decision}`)

            return `${value} ${unit} - ${localeDecision}`
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: '',
        },
      },
      y: {
        beginAtZero: true,
        max: Number(maxValue) + extraSpace,
        ticks: {
          stepSize: 1,
        },
        title: {
          display: true,
          text: unit,
        },
      },
    },
  }

  return (
    <Card>
      <CardHeader>
        <Typography
          color={COLORS.ECLIPSE}
          fontSize={TITLE_H2_SIZE}
          fontWeight={TITLE_H1_WEIGHT}
        >
          {`${t(productName)} ${t('global:level')}`}
        </Typography>
        <StyledSelectWrapper>
          <Select value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
            <option value="1m"><Trans k='global:lastMonth' /></option>
            <option value="3m"><Trans k='global:last3Months' /></option>
            <option value="6m"><Trans k='global:last6Months' /></option>
            <option value="1y"><Trans k='global:lastYear' /></option>
          </Select>
          <StyledArrow>
            <Image height={10} src={generateAssetUrl(arrowDownSVG)} width={10} />
          </StyledArrow>
        </StyledSelectWrapper>
      </CardHeader>
      <CardContent>
        <div>
          {0 === data.length ? (
            <p><Trans k="global:noData" /></p>
          ) : (
            <Bar data={chartData} options={options as any} />
          )}
        </div>
      </CardContent>
    </Card >
  )
}

export default BarChart
