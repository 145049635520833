import { apiCallInitialized } from '@probatix/common/services/toolkit/api/apiService.actions'
import { ActionParams } from '@probatix/common/utils/service'

import { getCurrentLocale } from '@probatix/common/translations'

import { API_MY_PROBATIX_ENTRYPOINT } from '@probatix/common/config/app'
import { LoginCredentials } from './auth.model'

import { slice } from './auth.reducer'

export const {
  loginFailed,
  loginSubmitted,
  loginSuccess,
  logout,
  meFailed,
  meFetching,
  meSuccess,
  setAuthData,
  updateAuthData,
} = slice.actions

const returnHeader = () => ({
  Accept: 'application/json',
  'Accept-Language': getCurrentLocale().code.slice(0, 2),
  'Content-Type': 'application/json',
})

export const login = (params: ActionParams<LoginCredentials>) => (dispatch) => {
  dispatch(
    // @ts-ignore
    apiCallInitialized({
      authorization: false,
      customEntrypoint: API_MY_PROBATIX_ENTRYPOINT,
      customHeaders: returnHeader(),
      method: 'POST',
      onError: loginFailed.type,
      onInit: loginSubmitted.type,
      onSuccess: loginSuccess.type,
      url: '/login',
      withCredentials: true,
      ...params,
    }),
  )
}
const apiUrl = API_MY_PROBATIX_ENTRYPOINT || process.env.REACT_APP_API_ENTRYPOINT || ''
// considering the REACT_APP_API_ENTRYPOINT in the form like https://my-demo-iltis.probatix.de/api/

export const me = (params) => (dispatch) => dispatch(
  apiCallInitialized({
    customHeaders: returnHeader(),
    method: 'GET',
    onError: meFailed.type,
    onInit: meFetching.type,
    onSuccess: meSuccess.type,
    url: `${apiUrl}/patients/me`,
    withCredentials: true,
    ...params,
  }),
)
