import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ lang }: { lang: string }) => (
  <Helmet>
    <meta content={lang} httpEquiv="content-language" />
    <meta content="width=device-width, initial-scale=1.0, maximum-scale=1" name="viewport" />
  </Helmet>
)

export default Meta
