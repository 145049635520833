import React from 'react'

import { Location } from '@probatix/common/services/RTKServices/locations/index.model'

import useSmallScreens from '@probatix/common/hooks/useSmallScreens'

import {
  Button,
  Container,
  Grid,
  Page,
  RowBootstrap,
  Space,
  Typography,
} from '@probatix/common/components/atoms'

import { Trans } from 'translations'

import LocationCard from '../components/LocationCard'

interface LocationPageViewProps {
  errors: any;
  isLoading: boolean;
  locations: Location[];
}

const LocationPageView: React.FC<LocationPageViewProps> = ({
  errors,
  isLoading,
  locations,
}) => {
  const [isSmallScreen] = useSmallScreens()

  const renderHeader = () => (
    <Typography
      align="center"
      fontSize="1.1rem"
      fontWeight={isSmallScreen ? 400 : 600}
      margin="0 0 35px 0"
    >
      <Trans k="modules:bookTest:LocationPage:selectALocation" />
    </Typography>
  )

  const renderBody = () => {
    if (!isLoading && (!locations?.length || errors)) {
      const message = errors ? (
        <Trans k="bookTest:LocationPage:locationNotFound" />
      ) : (
        <Trans k="bookTest:LocationPage:noAvailableSlots" />
      )

      return (
        <React.Fragment>
          <Space space="100px" />
          <Grid alignSelf="center" justifyContent="center">
            <Grid.Item>
              <Typography
                align="center"
                fontSize="24px"
                fontWeight={600}
                lineHeight="40px"
                padding="20px"
              >
                {message}
              </Typography>
              <Space space="40px" />
            </Grid.Item>
            <Grid.Item justifyContent="center">
              <Button
                fontSize="16px"
                label={(
                  <Trans
                    k="modules:bookTest:LocationPage:goBackTo"
                    params={{ siteName: 'my.probatix.de' }}
                  />
                )}
                padding="17px 32px"
                target="_blank"
                to="https://my.probatix.de"
                version="blue_light"
              />
              <Space space="120px" />
            </Grid.Item>
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <Container>
        <Space space="100px" />
        {renderHeader()}
        {locations.map((location) => <LocationCard key={location.locationCode} location={location} />)}
      </Container>
    )
  }

  return (
    <Page>
      <RowBootstrap>{renderBody()}</RowBootstrap>
    </Page>
  )
}

export default LocationPageView
