import styled from 'styled-components'

const logo = require('../../../assets/images/probatix-logo-white.png')

export const StyledLeftSide = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  display: flex;
  
  @media(max-width: 880px) {
    display: none;
  }
`

export const StyledLogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0 20px;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
`

export const StyledLogo = styled.div`
  max-width: 328px;
  width: 100%;
  height: 88px;
  background: url(${logo}) no-repeat center center;
  background-size: 100%;
`

export const StyledFooter = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 32px 20px;
  text-align: center;
  width: 100%;
  justify-content: center;
  max-width: calc(50vw - 40px);
  font-size: 0.875rem !important;
`
