import { useLocation } from 'react-router'

import { NullableString } from '@probatix/common/models/global'

export const useQuery = <T>(): { queryParams: T, queryString: string } => {
  const queryString = useLocation().search
  // @ts-ignore
  const queryParams: T = {}

  new URLSearchParams(queryString).forEach((v, k) => {
    queryParams[k] = v
  })

  return {
    queryParams,
    queryString,
  }
}

const getComparator = (comparator, value) => {
  if (!comparator && !value) {
    return ''
  }

  if (comparator) {
    return `[${comparator}]`
  }

  if (!comparator && Array.isArray(value)) {
    return '[]'
  }

  return ''
}

const getCriteria = (field, comparator = '', value) => {
  if (!Array.isArray(value) && 'not' === comparator) {
    return `not[${field}]=${encodeURIComponent(value)}`
  }

  if ('exists' === comparator) {
    return `exists[${field}]=${encodeURIComponent(value)}`
  }

  return `${field}${getComparator(comparator, value)}=${encodeURIComponent(value)}`
}

const getOrder = (order) => {
  const { sortField, sortOrder } = order

  if (sortField) {
    return `order[${sortField}]=${sortOrder || 'asc'}`
  }

  return null
}

interface QueryStringParams {
  criteria?: any
  groups?: any,
  limit?: number,
  order?: string | string[],
  page?: number
}

export const getQueryString = ({ criteria, groups, limit, order, page }: QueryStringParams = {}) => {
  const queryString = [] as string[]

  if (groups?.read) {
    queryString.push(...groups.read.map((item) => `groups[]=read:${item}`))
  }

  if (groups?.empty) {
    queryString.push(...groups.empty.map((item) => `groups[]=${item}`))
  }

  if (criteria && criteria.length) {
    criteria.forEach(({ comparator, field, value }) => {
      queryString.push(getCriteria(field, comparator, value))
    })
  }

  if (order) {
    if (Array.isArray(order)) {
      order.forEach((orderItem) => {
        const orderString = getOrder(orderItem)

        if (orderString) {
          queryString.push(orderString)
        }
      })
    } else {
      const orderString = getOrder(order)

      if (orderString) {
        queryString.push(orderString)
      }
    }
  }

  if (null !== limit && limit !== undefined) {
    queryString.push(`itemsPerPage=${limit}`)
  }

  if (page) {
    queryString.push(`page=${page}`)
  }

  return queryString.join('&')
}

export const getSubdomain = (): NullableString => {
  const { hostname } = window.location

  const parts = hostname.split('.')

  if (3 > parts?.length) {
    throw new Error(`Subdomain cannot be found in ${hostname}`)
  }

  return parts?.[0]
}

export const getDomain = (withSubdomain: boolean = true): string => {
  const { hostname } = window.location
  const parts = hostname.split('.')

  if (withSubdomain && 4 === parts?.length) {
    return `${parts[2]}.${parts[3]}`
  }

  if (withSubdomain && 3 === parts?.length) {
    return `${parts[1]}.${parts[2]}`
  }

  if (!withSubdomain && 2 === parts?.length) {
    return `${parts[0]}.${parts[1]}`
  }

  throw new Error(`Domain cannot be found in ${hostname}`)
}

export interface ApiErrorDetails {
  code: number
  message: string
  objectClass?: string
  propertyPath: string
}

export interface ApiError {
  code: number
  detail: string
  messageText: string
  violations: ApiErrorDetails[]
}

export const formatApiErrors = (error: ApiError): string[] => {
  const { violations = [] } = error

  return violations.map((d) => d.message)
}

export const getFirstErrorOrDetail = (error: ApiError): string => {
  const { violations = [] } = error

  if (violations[0] === undefined) {
    return error.detail
  }

  return violations[0].message
}

export const returnFirstErrorForPropertyPaths = (error: ApiError): Map<string, string> => {
  const { violations = [] } = error

  const errorMap = new Map<string, string>()

  violations.forEach((violation) => {
    const { message, propertyPath } = violation
    if (!errorMap.has(propertyPath)) {
      errorMap.set(propertyPath, message)
    }
  })

  return errorMap
}

export const replaceQueryString = (query: URLSearchParams) => window
  .history.pushState(null, '', `?${query.toString()}`)

