import React from 'react'
import { MY_PROBATIX_URL } from 'config/app'

import useAvailableProductTypes from '@probatix/common/hooks/useAvailableProductTypes'
import { Container, Grid, HtmlParser, Image, Space, Typography } from '@probatix/common/components/atoms'

import { COLORS } from '@probatix/common/constants/colors'

import { Location } from '@probatix/common/services/RTKServices/locations/index.model'

import useDefaultImg from '@probatix/common/hooks/useDefaultImg'
import dummyBG from '@probatix/common/assets/bg/dummy_bg.png'
import dummyLogo from '@probatix/common/assets/logo/dummy_logo.png'

import { CHECKOUT_IMG_HEIGHT, CHECKOUT_LOGO_HEIGHT } from '@probatix/common/constants/design'

import { generateAssetUrl } from '@probatix/common/components/atoms/TextField/TextField.helpers'

import { StyledCard, StyledCardBody, StyledCardImg, StyledCardImgOverlay } from './LocationCard.styled'

interface ILocationCard {
  location: Location;
}

const LocationCard: React.FC<ILocationCard> = ({ location }) => {
  const generateImagePath = (path) => (path ? generateAssetUrl(`https://${location?.tenant?.baseUrl!}${path}`) : null)
  const bgPath = generateImagePath(location?.backgroundImagePath)
  const logoPath = generateImagePath(location?.logoPath)

  const bgImg = useDefaultImg({
    dummyImg: generateAssetUrl(dummyBG),
    path: bgPath,
  })
  const logoImg = useDefaultImg({
    dummyImg: generateAssetUrl(dummyLogo),
    path: logoPath,
  })
  const { list } = useAvailableProductTypes(location?.availableProductTypes)

  const redirectToMyp = () => {
    window.location.href = `${MY_PROBATIX_URL}/checkout?location=${location.locationCode}`
  }

  return (
    <Container $maxWidth="643px">
      <StyledCard onClick={redirectToMyp}>
        <StyledCardImg
          height={CHECKOUT_IMG_HEIGHT}
          src={bgImg}
          variant="bottom"
          width="auto"
        />
        <StyledCardImgOverlay
          $margin={`${Number(CHECKOUT_IMG_HEIGHT) - (Number(CHECKOUT_LOGO_HEIGHT / 2) + 20)}px 0px`}
        >
          <Grid gridTemplateColumns="150px auto">
            <Grid.Item>
              <Image height={CHECKOUT_LOGO_HEIGHT} objectPosition="left" src={logoImg} width={150} />
            </Grid.Item>
            <Grid.Item />
          </Grid>
        </StyledCardImgOverlay>
        <StyledCardBody>
          <Space space="30px" />
          <Grid gap="10px" gridTemplateColumns="min-content auto">
            <Grid.Item>
              <Typography fontSize="1.1rem" fontWeight={600}>
                {location?.name}
              </Typography>
            </Grid.Item>
            <Grid.Item>
              <Typography fontSize="0.9rem" fontWeight={400} lineHeight="27px">
                {location?.address}
              </Typography>
            </Grid.Item>
          </Grid>
          <Typography color={COLORS.BLUE_LIGHT} fontSize="0.7rem" fontWeight={600}>
            {list}
          </Typography>
          {location?.description && (
            <Typography fontSize="0.7rem" lineHeight="0.875rem" variant="div">
              <HtmlParser description={location?.description} />
            </Typography>
          )}
        </StyledCardBody>
      </StyledCard>
      <Space space="50px" />
    </Container>
  )
}

export default LocationCard
