export const SESSION_KEY: string = 'CHECKOUT_FORM'
export const ENV: string = process.env.REACT_APP_APP_ENV || 'local'
export const DEFAULT_LOCALE: string = process.env.REACT_APP_LOCALE || 'de'

// process envs
export const API_ENTRYPOINT: string = process.env.REACT_APP_API_ENTRYPOINT || 'https://*.probatix.de'
export const MY_PROBATIX_URL: string = process.env.REACT_APP_MY_PROBATIX_URL || 'http://probatix.localhost:4000'
export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN || ''

export const COOKIE_POLICY_KEY: string = 'COOKIE_POLICY'

export const API_MY_PROBATIX_ENTRYPOINT: string =
    process.env.REACT_APP_CORE_API_ENTRYPOINT ?
      (`${process.env.REACT_APP_CORE_API_ENTRYPOINT}/api-myp`) :
      'http://probatix.localhost:8001/api'
export const GIT_VERSION: string = process.env.REACT_APP_GIT_VERSION || 'v999.99-dev'
