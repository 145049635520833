export const MYPROBATIX_COLORS = {
  GREEN1: 'rgba(0, 217, 219, 0.12)',
  GREY1: 'rgba(165, 165, 165, 0.2)',
  GREY3: '#595959',
  GREY4: '#757575',
  GREY5: '#2D2D2D',
  PURPLE1: 'rgba(1, 1, 1, 0.12)',
  PURPLE2: 'rgba(152, 82, 249, 0.12)',
  RED1: 'rgba(219, 0, 0, 0.12)',
}

export const BREAKPOINTS = {
  desktop: '2560px',
  laptop: '1024px',
  laptopL: '1440px',
  mobileL: '425px',
  mobileM: '375px',
  mobileS: '320px',
  tablet: '768px',
  tabletS: '500px',
}

export const MAP_MOBILE_SIZE = {
  HEIGHT: 102,
  WIDTH: 268,
}

export const MAP_DESKTOP_SIZE = {
  HEIGHT: 121,
  WIDTH: 384,
}

export const APPOINTMENT_MAP_DESKTOP_SIZE = {
  HEIGHT: 200,
  WIDTH: 352,
}

export const APPOINTMENT_MAP_MOBILE_SMALL_SIZE = {
  HEIGHT: 200,
  WIDTH: 216,
}

export const TITLE_WIDTH_SMALL_SIZE = '90vw'
export const TITLE_WIDTH_DESKTOP_SIZE = '70vw'
export const BORDER_RADIUS = '6px'
