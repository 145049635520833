import styled from 'styled-components'
import { Badge } from 'react-bootstrap'

interface IStyledBadge {
  $textColor?: string;
  bg?: string;
}

export const StyledBadge = styled(Badge) <IStyledBadge>`
  margin-left: -5px;
  text-transform: capitalize;
  font-size: 0.82225rem;
  ${(props) => props.bg && `
    background: ${props.bg}
  `};
  ${(props) => props.$textColor && `
    color: ${props.$textColor}
`};
  font-weight: 700;
  width: fit-content;
`
