import React, { PropsWithChildren } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { StyledContainer } from './Container.styled'

interface IContainer {
  $margin?: string;
  $maxHeight?: string;
  $maxWidth?: string;
  $minHeight?: string;
  $minWidth?: string;
  $padding?: string;
  className?: string;
  fluid?: boolean;
}

const Container: React.FC<PropsWithChildren<IContainer>> = (
  {
    $margin,
    $maxHeight,
    $maxWidth,
    $minHeight,
    $minWidth,
    $padding,
    children,
    className,
    fluid,
  },
  props,
) => (
  // @ts-ignore
  <AnimatePresence>
    <motion.div
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      initial={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.2 }}
    >
      <StyledContainer
        $margin={$margin}
        $maxHeight={$maxHeight}
        $maxWidth={$maxWidth}
        $minHeight={$minHeight}
        $minWidth={$minWidth}
        $padding={$padding}
        className={className}
        fluid={fluid}
        {...props}
      >
        {children}
      </StyledContainer>
    </motion.div>
  </AnimatePresence>
)

export default Container
