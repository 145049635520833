import { createSlice } from '@reduxjs/toolkit'

export interface BasicModalProps {
  onRequestClose?: (next: () => void) => {},
}

export type ModalOptions = {
  enableMultipleModal?: boolean
}

export type ModalSingleType = {
  modalOptions?: ModalOptions,
  modalProps: BasicModalProps,
  modalType: any,
}

export interface ModalCommonServiceState {
  modalStack: ModalSingleType[]
  modalStackIsEnabled: boolean
}

const initialState = {
  modalStack: [],
  modalStackIsEnabled: false,
} as ModalCommonServiceState

export default createSlice({
  initialState,
  name: 'modalCommon',
  reducers: {
    hide(state) {
      if (state.modalStackIsEnabled) {
        state.modalStack = 1 < state?.modalStack?.length
          ? state.modalStack.slice(0, state.modalStack.length - 1)
          : []
        state.modalStackIsEnabled = 1 < state?.modalStack?.length
      } else {
        state.modalStack = []
        state.modalStackIsEnabled = false
      }
    },
    hideAllModal(state) {
      state.modalStack = []
      state.modalStackIsEnabled = false
    },
    show<T extends keyof any>(state, { payload: { modalOptions, modalProps, modalType } }: {
      payload: {
        modalOptions?: ModalOptions,
        modalProps?: BasicModalProps & any[T],
        modalType: T,
      }
    }) {
      state.modalStack = [
        ...(state.modalStackIsEnabled ? state.modalStack : []), {
          modalOptions,
          modalProps,
          modalType,
        },
      ]
      state.modalStackIsEnabled = state.modalStackIsEnabled
        ? true
        : !!(modalOptions?.enableMultipleModal)
    },
  },
})
