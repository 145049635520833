import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'

interface IStyledContainer {
  display?: string,
  size?: string
}

interface IStyledSpinnerAnimation {
  size?: string
  variant?: string
}

export const StyledContainer = styled.div<IStyledContainer>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  
  ${({ size }) => 'small' === size && `
    padding: 0;
    margin-left: 20px;
  `}

  ${({ size }) => 'internal' === size && `
    padding: 0;
    margin-left: 0px;
  `}
  
  ${({ display }) => 'inline' === display && `
    display: inline;
  `}
`

export const StyledSpinnerAnimation = styled.div<IStyledSpinnerAnimation>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  will-change: transform;
  animation: spinner-animation 600ms infinite linear;
  
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }
  
  &:before {
    border: 3px solid ${COLORS.BLUE_LIGHT};
    opacity: .15;
    
    ${({ variant }) => 'white' === variant && `
      border: 3px solid ${COLORS.WHITE};
    `}
  }
  
  &:after {
    border: 3px solid transparent;
    border-left-color: ${COLORS.BLUE_LIGHT};
    
    ${({ variant }) => 'white' === variant && `
      border-left-color: ${COLORS.WHITE};
    `}
  }
  
  &,
  &:before,
  &:after {
    width: 35px;
    height: 35px;
    
    ${({ size }) => 'small' === size && `
      width: 20px;
      height: 20px;
    `}

    ${({ size }) => 'internal' === size && `
      width: 20px;
      height: 20px;
    `}
  }

  @keyframes spinner-animation {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
`

export const StyledSpinner = styled.div<IStyledSpinnerAnimation>`
  width: 35px;
  height: 35px;
  position: relative;
  color: ${COLORS.BLUE};
  display: inline-block;
  font-size: 24px;
  
  ${({ size }) => 'small' === size && `
    width: 20px;
    height: 20px;
  `}
  
  ${({ variant }) => 'white' === variant && `
    color: ${COLORS.WHITE};
  `}
`
