import styled from 'styled-components'

export const StyledPageBackgroundWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  background: linear-gradient(90deg, rgba(31,41,58,1) 0%, rgba(31,41,58,1) 50%, rgba(255,255,255,1) 50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  @media(max-width: 880px) {
    display: none;
  }
`

export const StyledPageContent = styled.div`
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100vh;
  padding: 0 20px;
  max-width: 1520px;
  margin: 0 auto;
  width: 100%;
  
  @media(max-width: 880px) {
    grid-template-columns: auto;
    width: 100vw;
  }
`

export const StyledRightSide = styled.div`
  height: 100vh;

  @media(max-width: 500px) {
    max-width: 100vw;
  }
`

export const StyledHeader = styled.div`
  padding: 0 25px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledContent = styled.div`
  padding: 40px 25px;
`
