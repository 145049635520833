import React from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from '@probatix/common/constants/design'
import { COLORS } from '@probatix/common/constants/colors'

export const StyledModalWrapper = styled.div`
`

/* eslint-disable @typescript-eslint/no-unused-vars */
export const StyledHeader = styled(({
  hasBorderLessHeader,
  ...rest
}) => (<header {...rest} />))`
  border-bottom: 1px solid ${COLORS.CADET_BLUE};
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  text-align: center;
  padding-bottom: 20px;

  ${({ hasBorderLessHeader }) => hasBorderLessHeader && `
    border-bottom: none;
  `}
  
  @media(max-width: ${MOBILE_WIDTH}px) {
    padding: 15px 10px;
  }
`

export const StyledBody = styled.div`
  padding: 35px 25px;
  
  @media(max-width: ${MOBILE_WIDTH}px) {
    padding: 25px 15px;
  }
`

export const StyledCloseButton = styled.button`
  background: rgba(0, 0, 0, 0.45);
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  & > div {
    transform: rotate(45deg);
  }
`
