import Cookies from 'js-cookie'

import { PayloadAction } from '@reduxjs/toolkit'

import { ActionParams } from '@probatix/common/utils/service'

import { AUTH_SUCCESSFUL_KEY } from '@probatix/common/services/toolkit/auth/auth.constants'

import { authServiceActions } from '@probatix/common/services/toolkit/auth'
import { getDomain } from '@probatix/common/utils/url'

type Action = PayloadAction<ActionParams<object>>

const logoutMiddleware = (withSubdomain: boolean = true) => () => (next) => async (action: Action) => {
  if (!authServiceActions.logout.match(action)) {
    return next(action)
  }

  // NOTE: middleware used for all of the probatix services to logout user from all of the serivces based on
  // the single sign on
  Cookies.remove(AUTH_SUCCESSFUL_KEY, { domain: `.${getDomain(withSubdomain)}` })

  return next(action)
}

export default logoutMiddleware
