export interface BaseUrlInterface {
  apiPath: string
  customEntrypoint?: boolean
  entrypoint: string
  subdomain?: string | null,
  withRelativeUrl?: boolean
}

export const getBaseUrl = ({
  apiPath,
  customEntrypoint,
  entrypoint,
  subdomain,
  withRelativeUrl,
}: BaseUrlInterface): string => {
  let base: string = ''

  if (customEntrypoint || (!withRelativeUrl && !subdomain)) {
    return entrypoint
  }

  if (!withRelativeUrl && subdomain) {
    base = entrypoint.replace('*', subdomain)
  }

  if (apiPath) {
    return `${base}${apiPath}`
  }

  return base
}
