import React from 'react'
import { useSmallScreens } from '@probatix/common/hooks'
import { CustomDialog, CustomDrawer, Space } from '@probatix/common/components/atoms'

const CustomModal = React.forwardRef(({ children,
  hideCrossButton,
  isOpen,
  key,
  onClose,
  onOpen,
}: any, forwardedRef) => {
  const [isSmallScreen] = useSmallScreens()

  const handleToggle = (open) => {
    if (open) {
      onOpen()
    } else {
      onClose()
    }
  }

  const dialog = () => (
    <CustomDialog
      hideCrossButton={hideCrossButton}
      key={key}
      open={isOpen}
      onToggleChange={handleToggle}
    >
      {children}
    </CustomDialog >
  )

  const drawer = () => (
    <CustomDrawer
      isOpen={isOpen}
      key={key}
      ref={forwardedRef}
      onToggle={handleToggle}
    >
      {children}
      <Space space="20px" />
    </CustomDrawer>
  )

  if (!isOpen) {
    return <span />
  }

  return (isSmallScreen ? drawer() : dialog())
})

export default CustomModal
