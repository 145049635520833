import styled from 'styled-components'

export const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
`

export const StyledCheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 10px;
  align-items: center;
`
