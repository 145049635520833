import { getBasicSliceActions, getBasicListAction } from '@probatix/common/utils/service'
import { slice } from './productListService'

const URL = '/products'

export const {
  productsListFailed,
  productsListFetching,
  productsListReset,
  productsListSuccess,
} = getBasicSliceActions(slice.actions)

export const list = (params: object = {}): object => getBasicListAction({
  onError: productsListFailed.type,
  onInit: productsListFetching.type,
  onSuccess: productsListSuccess.type,
  url: URL,
  ...params,
})
