import React from 'react'
import {
  StyledDrawerContent,
  StyledDrawerHandle,
  StyledDrawerOverlay,
  StyledDrawerPortal,
  StyledDrawerContentContainer,
  StyledDrawerRoot,
  StyledDialogTitle,
} from './CustomDrawer.styled'

const CustomDrawer = ({ children, isOpen, onToggle, ...props }) => (
  <StyledDrawerRoot
    open={isOpen}
    setBackgroundColorOnScale={true}
    snapPoints={[1]}
    onClose={() => { onToggle(false) }}
    onOpenChange={onToggle}
  >
    <StyledDrawerPortal>
      <StyledDrawerContent {...props} aria-describedby={undefined}>
        <StyledDrawerHandle />
        <StyledDialogTitle />
        <StyledDrawerContentContainer>
          {children}
        </StyledDrawerContentContainer>
      </StyledDrawerContent>
      <StyledDrawerOverlay />
    </StyledDrawerPortal>
  </StyledDrawerRoot>
)

export default CustomDrawer
