import React from 'react'

import { IconType } from '@probatix/common/constants/icons'

import { StyledContainer } from './Icon.styled'

interface IconProps {
  color?: string
  cursor?: string
  dataQa?: string
  icon: IconType
  onClick?: (e?: any) => void
  size?: number
  style?: React.CSSProperties
}

const Icon: React.FC<IconProps> = ({
  color = 'rgba(0, 0, 0, 0.85)',
  cursor,
  dataQa,
  icon,
  onClick,
  size = 24,
  style,
}) => {
  const cssStyle = {
    color,
    fontSize: size,
  }

  return (
    <StyledContainer cursor={cursor} data-qa={dataQa} style={style} onClick={onClick || null}>
      <div
        className={`icons icons-${icon}`}
        style={cssStyle}
      />
    </StyledContainer>
  )
}

export default Icon
