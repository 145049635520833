import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'
import { MYPROBATIX_COLORS } from '@probatix/myprobatix/src/constants'

interface IStyledBiomarkerCard {
  noLine?: boolean
}

export const StyledBiomarkerCard = styled.div<IStyledBiomarkerCard>`
  padding: 22px 0;
  border-bottom: ${(props) => ((props.noLine) ? 0 : `1px solid ${COLORS.CADET_BLUE}`)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const StyledBiomarkerName = styled.div`
  background: ${MYPROBATIX_COLORS.PURPLE2};
  border-radius: 8px;
  padding: 2px 8px;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 24px;
  text-align: center;
  color: ${MYPROBATIX_COLORS.GREY3};
  margin-bottom: 8px;
`
export const StyledBiomarkerDescription = styled.div`
  font-family: 'Figtree';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  h1 {
    font-size: 1rem;
    font-weight: 700;
  }

  p {
    font-size: 0.775rem;
    font-weight: 400;
  }
`
export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
`
export const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
export const StyledProductName = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 46px;
`
