import React, { PropsWithChildren } from 'react'

import { noop } from '@probatix/common/constants/global'

import { TypographyPropsBasic } from './Typography.model'
import { StyledTypography } from './Typography.styled'

export interface TypographyProps extends TypographyPropsBasic {
  ref?: React.Ref<any>
}

const Typography: React.FC<PropsWithChildren<TypographyProps>> = React.forwardRef((
  {
    children,
    linkColorInherit = true,
    variant = 'p',
    onClick = noop,
    ...rest
  }: any,
  ref,
) => (
  <StyledTypography
    as={variant}
    linkColorInherit={linkColorInherit}
    ref={ref}
    variant={variant}
    onClick={onClick}
    {...rest}
  >
    {children}
  </StyledTypography>
))

export default Typography
