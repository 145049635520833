import color from 'color'
import { COLORS } from '@probatix/common/constants/colors'
import styled from 'styled-components'

interface ILanguageMenu{
  paddingInlineEnd?: string
}

export const StyledLanguageMenu = styled.ul<ILanguageMenu>`
  list-style: none;
  display: flex;
  margin: auto;
  ${({ paddingInlineEnd }) => (paddingInlineEnd
     && `padding-inline-end: ${paddingInlineEnd};`)
}`

export const StyledLanguageItem = styled.li`
  color: ${color(COLORS.BLACK).alpha(0.45)};
  display: flex;
  cursor: pointer;

  &:after {
    display: inline-block;
    content: '/';
    margin: 2px 10px;
    color: ${color(COLORS.BLACK).alpha(0.45)};
  }
  
  &:nth-last-of-type(1):after {
    display: none;
  }
  
  
  ${({ $active }) => $active && `
    color: #000;
  `}
`
