import { PayloadAction } from '@reduxjs/toolkit'
import { ActionParams } from '@probatix/common/utils/service'

import { authServiceActions, authServiceSelectors } from '@probatix/common/services/toolkit/auth'
import { testsServiceActions } from '@probatix/common/services/toolkit/tests'
import { userServiceHelpers } from '@probatix/common/services/toolkit/user'

import { RootState } from '../reducers'

type Action = PayloadAction<ActionParams<object>>

const setAccountUsersMiddleware = ({ dispatch, getState }) => (next) => async (action: Action) => {
  if (!authServiceActions.setAuthData.match(action)) {
    return next(action)
  }

  const { auth } = getState() as RootState

  if (!auth.data) {
    return next(action)
  }

  const authenticatedUser = authServiceSelectors.getUser(auth.data)
  if (!authenticatedUser) {
    return next(action)
  }

  const results = next(action)

  /* if you want to add child users and remove checked from main users checkbox,
  * replace true with !!person?.main?.selected.
  */
  dispatch(testsServiceActions.saveMainPerson(
    userServiceHelpers.transformAuthenticatedUserToCustomer(authenticatedUser, true),
  ))

  return results
}

export default setAccountUsersMiddleware
