import styled from 'styled-components'
import { Container } from 'react-bootstrap'

interface IStyledContainer {
  $margin?: string;
  $maxHeight?: string;
  $maxWidth?: string;
  $minWidth?: string;
  $padding?: string;
}

export const StyledContainer = styled(Container) <IStyledContainer>`
  padding-left: 10px;
  padding-right: 10px;
  max-width: ${(props) => (props.$maxWidth ? `${props.$maxWidth}` : '1000px')};
  ${(props) => props.$padding && `padding: ${props.$padding}`};
  ${(props) => props.$minWidth && `min-width: ${props.$minWidth}`};
  margin: ${(props) => (props.$margin ? `${props.$margin} ` : 'auto')};
  ${(props) => props.$maxHeight && `max-height: ${props.$maxHeight}`};
  ${(props) => props.$minHeight && `min-height: ${props.$minHeight}`};
`
