import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppServiceState } from './appService.model'

const initialState = {
  hiddenLocations: [],
  hiddenProducts: [],
  subdomain: null,
} as AppServiceState

export default createSlice({
  initialState,
  name: 'app',
  reducers: {
    addHiddenLocation: (state: AppServiceState, action: PayloadAction<string>) => {
      state.hiddenLocations.push(action.payload)
    },
    addHiddenProduct: (state: AppServiceState, action: PayloadAction<string>) => {
      state.hiddenProducts.push(action.payload)
    },
    subdomain: (state: AppServiceState, action: PayloadAction<string>) => {
      state.subdomain = action.payload
    },
  },
})
