import styled from 'styled-components'

export const StyledWrapper = styled.div`
  padding: 20px;
  min-width: 200px;
`

export const StyledButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;  
`

export const StyledContent = styled.div`
  padding: 15px 15px 25px;
  text-align: center;
`
