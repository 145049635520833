
import styled from 'styled-components'
import Card from 'react-bootstrap/Card'

interface ICardImgOverlay {
  $margin: String
}
export const StyledCard = styled(Card)`
  cursor: pointer;
`

export const StyledCardBody = styled(Card.Body)``

export const StyledCardImg = styled(Card.Img)`
  object-fit: cover;
  border-radius: 6px;
`

export const StyledCardImgOverlay = styled(Card.ImgOverlay)<ICardImgOverlay>`
  margin:  ${({ $margin }) => ($margin || '340px 0px; '
  )};
`
