import styled from 'styled-components'

import { PAGE_WIDTH } from '@probatix/common/constants/design'

export const StyledPageWrapper = styled.div`
    max-width: 100vw;
    margin: 0 auto;
    width: 100%;

  @media(min-width: 520px) and (max-width: ${PAGE_WIDTH}px)   {
    width: 100%
  }

  @media screen  and (max-device-width: 480px) and (orientation: portrait) { 
    max-width: 100vw;
    padding: 10px;
  }

`
