import * as selectors from './appService.selectors'
import appService from './appService'

export const appServiceSelectors = {
  ...selectors,
}

export const appServiceActions = {
  ...appService.actions,
}

export const appServiceReducers = appService.reducer
