import { useMediaPredicate } from 'react-media-hook'

import {
  DEFAULT_MOBILE_MAX_WIDTH,
  SMALL_DESKTOP_MAX_WIDTH,
} from '../constants/design'

const useSmallScreens = () => {
  const isMobile = useMediaPredicate(DEFAULT_MOBILE_MAX_WIDTH)
  const isSmallDesktop = useMediaPredicate(SMALL_DESKTOP_MAX_WIDTH)
  const isSmallScreen = (isMobile || isSmallDesktop)

  return [isSmallScreen, isMobile, isSmallDesktop] as const
}

export default useSmallScreens
