import React, { InputHTMLAttributes } from 'react'

import useSmallScreens from '@probatix/common/hooks/useSmallScreens'

import {
  StyledInput,
  StyledInputWrapper,
  StyledLabel,
  StyledRight,
  StyledInputBox,
} from './Input.styled'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  $hasError: boolean;
  autoComplete?: string;
  checkout?: boolean;
  disabled?: boolean;
  errorMargin?: string;
  label: string | React.ReactNode;
  margin?: string;
  max?: string | number;
  maxWidth?: string;
  minWidth?: string;
  name: string;
  placeholder?: never;
  required?: boolean;
  right?: React.ReactNode;
  textTransform?: string;
  type?: string;
}

const Input: React.FC<InputProps> = React.forwardRef(
  (
    {
      $hasError,
      autoComplete,
      checkout,
      disabled,
      label,
      margin,
      max,
      maxWidth,
      minWidth,
      name,
      placeholder,
      required,
      right,
      textTransform = 'none',
      type,
      ...rest
    },
    ref,
  ) => {
    const [isSmallScreen] = useSmallScreens()

    return (
      <StyledInputWrapper
        $disabled={disabled}
        $hasError={$hasError}
        checkout={checkout}
        margin={margin}
      >
        <StyledInputBox checkout={checkout}>
          <StyledInput
            autoComplete={autoComplete}
            checkout={checkout}
            data-qa={name}
            data-testid={name}
            disabled={disabled}
            isSmallScreen={isSmallScreen}
            max={max}
            maxWidth={maxWidth}
            minWidth={minWidth}
            name={name}
            placeholder={placeholder}
            ref={ref}
            required={required}
            textTransform={textTransform}
            type={type}
            {...rest}
          />
          <StyledLabel>{label}</StyledLabel>
          {right && <StyledRight>{right}</StyledRight>}
        </StyledInputBox>
      </StyledInputWrapper>
    )
  },
)

export default Input
