import React from 'react'
import { RouteMatch, RouteObject } from 'react-router/lib/router'

import BookTestRouting from 'modules/BookTest/BookTest.routing'
import { ShellView } from './shell'

interface CustomRouteObject extends RouteObject {
  name?: string
}

export interface Route extends RouteMatch {
  route: CustomRouteObject
}

export default [
  {
    children: [
      ...BookTestRouting,
    ],
    element: <ShellView />,
    path: ':locale',
  },
]
