import { useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import i18n from 'translations'

export type UseLocaleAwareRouteResult = {
  generatePath: (route: string) => string;
  generatePathWithLocale: (route: string) => string;
  locale: string | undefined;
  replaceWithLocale: () => string;
}

const useLocaleAwareRoute = (): UseLocaleAwareRouteResult => {
  const { locale } = useParams()
  const { pathname, search } = useLocation()

  const uriWithoutLocale = pathname.split(/^\/([a-z]{2})\//).filter((i) => !!i)

  const generatePath = useCallback((route: string) => {
    if (locale) {
      return `/${i18n.language}/${route}`
    }

    return route
  }, [])

  const generatePathWithLocale = useCallback((route: string) => `/${i18n.language}/${route}`, [])

  const replaceWithLocale = useCallback(() => {
    if (2 > uriWithoutLocale.length) {
      return `${pathname}${search}`
    }

    return generatePath(`${uriWithoutLocale[1]}${search}`)
  }, [uriWithoutLocale])

  return {
    generatePath,
    generatePathWithLocale,
    locale,
    replaceWithLocale,
  }
}

export default useLocaleAwareRoute
