import styled from 'styled-components'
import React from 'react'

import { COLORS } from '@probatix/common/constants/colors'
import {
  INPUT_HEIGHT_DESKTOP,
  INPUT_HEIGHT_MOBILE,
  MAX_SMALL_DESKTOP,
  MIN_BIG_MOBILE_DEVICE,
  MOBILE_WIDTH,
} from '@probatix/common/constants/design'

interface IStyledInputBox {
  checkout: boolean
}

interface IStyledInput {
  checkout: boolean
  isSmallScreen: boolean
  textTransform?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledInputWrapper = styled(({ $disabled, checkout, hasError, margin, ...rest }) => (
  <div {...rest} />
))`
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.CADET_BLUE};
  width: 100%;
  display: block;
  position: relative;
  outline: none !important;

  ${({ $disabled }) => $disabled && `
    background: ${COLORS.NEUTRAL};
  `}

  ${({ $hasError }) => $hasError && `
    border-color: ${COLORS.ERROR};
  `}

  border-radius: 0.3125rem;
  ${({ checkout }) => checkout && `
    height: 4rem;
    border-radius: 1.375rem;
  `}

  ${({ margin }) => margin && `
    margin: ${margin};
  `}
`

export const StyledLabel = styled.div`
  color: ${COLORS.CADET_BLUE};
  position: absolute;
  left: 0.94rem;
  transition: all 0.25s ease;
  font-size: 0.625rem;
  top: 0.625rem;
  transform: translateY(0);
  z-index: 11;
  user-select: none;
  pointer-events: none;
`

export const StyledInput = styled.input<IStyledInput>`
  border: 0;
  background: transparent;
  width: 100%;
  font-size: 0.8rem;
  position: relative;
  z-index: 10;
  padding: 2.125rem 1.375rem 0.8rem 0.94rem;
  
  @media(min-width: ${MAX_SMALL_DESKTOP}px) {
    min-width: 280px;
  }

  @media((min-width: ${MIN_BIG_MOBILE_DEVICE}px) and (max-width: ${MAX_SMALL_DESKTOP}px)) {
    min-width: 240px;
  }

  height: ${({ isSmallScreen }) => (isSmallScreen ? INPUT_HEIGHT_MOBILE : INPUT_HEIGHT_DESKTOP)};

  border-radius: 0.3125rem;
  ${({ checkout }) => checkout && `
    height: 4rem;
    border-radius: 1.375rem;
  `}
  
  ${({ textTransform }) => textTransform && `
    text-transform: ${textTransform};
  `}

  ${({ minWidth }) => (minWidth && `
    
    @media(min-width: ${minWidth}) {
      min-width: ${minWidth};
    }
  `)}


  ${({ maxWidth }) => (maxWidth && `
    @media(max-width: ${maxWidth}) {
      max-width: ${maxWidth};
    }
  `)}

  &:placeholder-shown ~ ${StyledLabel} {
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8rem;
  }

  &:focus ~ ${StyledLabel}, :focus-visible ~ ${StyledLabel} {
   top: 0.625rem;
    font-size: 0.625rem;
    transform: translateY(0);
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media(max-width: ${MOBILE_WIDTH}px) {
    padding: 36px 0.94rem 12px;

    &:focus ~ ${StyledLabel} {
     top: 0.625rem;
      font-size: 0.625rem;
    }
  }

  &:focus-visible {

      border: 0px;
      outline: none;
  }
`

export const StyledInputBox = styled.div <IStyledInputBox>`
  display: flex;
  border-radius: 0.3125rem;
  ${(props) => (props.checkout) && `
    border-radius: 1.375rem;
  `}
`

export const StyledRight = styled.div`
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0px;
  cursor: pointer;
  opacity: 0.7;

  ${(props) => ((props.right)
    && `
    &:focus {
      border: 0px;
      outline: none;
    }
  `)}
`
