import * as React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { COLORS } from '@probatix/common/constants/colors'

import {
  StyledDialogContent,
  StyledDialogCrossButton,
  StyledDialogOverlay,
} from "./CustomDialog.styled"

import Button from '../Button'

const CustomDialog = ({
  children,
  hideCrossButton,
  onToggleChange,
  open,
}: any) => (
  <Dialog.Root
    defaultOpen={true}
    open={open}
    onOpenChange={() => onToggleChange()}
  >
    <Dialog.Portal>
      <StyledDialogOverlay />
      <StyledDialogContent>
        <StyledDialogCrossButton>
          {
            !hideCrossButton && <Button
              icon="close"
              iconColor={COLORS.BLACK}
              iconSize={25}
              label=""
              padding="0"
              textColor={COLORS.BLACK}
              version="transparent"
              onClick={() => {
                onToggleChange()
              }}
            />
          }
        </StyledDialogCrossButton>
        {children}
      </StyledDialogContent>
    </Dialog.Portal>
  </Dialog.Root>
)

export default CustomDialog