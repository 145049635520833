import { NullableString } from '@probatix/common/models/global'

import { getBasicSliceActions, getBasicListAction, ActionParams } from '@probatix/common/utils/service'

import { slice } from './availableDaysListService'

export const {
  availableDaysListFailed,
  availableDaysListFetching,
  availableDaysListSuccess,
} = getBasicSliceActions(slice.actions)

export const list = (
  location: NullableString,
  product: NullableString,
  params: ActionParams<object> = {},
): object => getBasicListAction({
  customApiPrefix: '',
  onError: availableDaysListFailed.type,
  onInit: availableDaysListFetching.type,
  onSuccess: availableDaysListSuccess.type,
  url: `${'/api/v2/checkout'}${`/available-days/${product}/${location}`}`,
  ...params,
})
