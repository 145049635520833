import React from 'react'

import { useGetUnGroupedLocations } from '@probatix/common/services/RTKServices/locations'

import { Spinner } from '@probatix/common/components/atoms'
import LocationPageView from './LocationPage.view'

const LocationPageContainer: React.FC = () => {
  const { data: locations, isError, isLoading, isSuccess } = useGetUnGroupedLocations({
    customEntryPoint: true,
  })

  return (
    <div data-testid="location-page">
      {
        isSuccess
          ? (
            <LocationPageView
              errors={isError}
              isLoading={isLoading}
              locations={locations}
            />
          )
          : <Spinner />
      }
    </div>
  )
}

export default LocationPageContainer
