import React from 'react'
import DOMPurify from 'dompurify'
import { StyledDescription } from './HtmlParser.styled'

interface HtmlParserProps {
  description: string
}

const HtmlParser: React.FC<HtmlParserProps> = ({ description }) => (
   
  <StyledDescription dangerouslySetInnerHTML={{
    __html: DOMPurify.sanitize(description, {
      ALLOWED_ATTR: ['target', 'href'],
    }),
  }}
  />
)

export default HtmlParser
