import React, { PropsWithChildren } from 'react'
import { Row as RowB } from 'react-bootstrap'

interface IRow {
  className?: any,
  dir?: any
}

const RowBootstrap: React.FC<PropsWithChildren<IRow>> = ({ children, className, dir }, props) => (
  <RowB className={className} dir={dir} {...props}>
    {children}
  </RowB>
)

export default RowBootstrap
