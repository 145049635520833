import React from 'react'

import { StyledGridItem } from './Grid.styled'

const GridItem: any = ({
  alignItems,
  borderLeft,
  children,
  justifyContent,
  margin,
  minWidth,
  padding,
}) => (
  <StyledGridItem
    alignItems={alignItems}
    borderLeft={borderLeft}
    justifyContent={justifyContent}
    margin={margin}
    minWidth={minWidth}
    padding={padding}
  >
    {children}
  </StyledGridItem>
)

export default GridItem
