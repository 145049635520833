import { combineReducers } from 'redux'

import productListService from './list/productListService'
import * as selectors from './list/productListService.selectors'
import * as actions from './list/productListService.actions'
import * as helpers from './productService.helpers'

export const productServiceActions = {
  ...actions,
}

export const productServiceSelectors = {
  ...selectors,
}

export const productServiceHelpers = {
  ...helpers,
}

export const productServiceReducers = combineReducers({
  list: productListService,
})
