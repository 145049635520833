import {
  getBasicSliceActions,
  getBasicUpdateSingleAction,
  ActionParams,
} from '@probatix/common/utils/service'
import { User } from '@probatix/common/services/RTKServices/patients/index.model'
import { API_MY_PROBATIX_ENTRYPOINT } from '@probatix/common/config/app'
import { slice } from './userSingleService'

const URL = '/patients'

export const {
  usersSingleFailed,
  usersSingleSubmitting,
  usersSingleSuccess,
} = getBasicSliceActions(slice.actions)

export const editUser = (uuid: string, params: ActionParams<User>): object => getBasicUpdateSingleAction({
  customEntrypoint: API_MY_PROBATIX_ENTRYPOINT,
  onError: usersSingleFailed.type,
  onInit: usersSingleSubmitting.type,
  onSuccess: usersSingleSuccess.type,
  url: `${URL}/${uuid}`,
  ...params,
})
