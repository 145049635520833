import { COLORS } from '@probatix/common/constants/colors'
import styled from 'styled-components'

export const StyledTooltip = styled.div`
    padding: 6px 8px;
    top: 50%;
    min-width: 120px ;
    min-height: 50px;
    border-radius:8px;
    background: ${COLORS.PIN_BLUE};
    color: #fff;
    text-align:center;
    display:table-cell;
    vertical-align: middle;
    box-shadow: rgb(0, 0, 0, 0.1) 0px 20px 25px -5px, 
      0px 10px 10px -5px rgb(0, 0, 0, 0.4);

    &:after {
      content: " ";
      position: absolute;
      bottom: -5px;
      border-top: 5px solid ${COLORS.PIN_BLUE};
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-bottom: none;
    }
`

export const StyledMapContainer = styled.div``

