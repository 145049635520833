import { HTTP_ACCESS_FORBIDDEN, HTTP_NOT_FOUND } from '@probatix/common/constants/http'

import { BasicSingleInitialState, BasicState } from '@probatix/common/utils/service'

import { AppServiceRootState, AppServiceState } from './appService.model'

export const selectIsSubmitting = (state: BasicSingleInitialState): boolean => state.submitting
export const selectIsFetching = (state: BasicState): boolean => state.fetching
export const selectIsInitialized = (state: BasicState): boolean => state.initialized

export const selectIsResourceNotFound = (state): boolean => 0 <= [HTTP_NOT_FOUND, HTTP_ACCESS_FORBIDDEN]
  .indexOf(state.errors?.status)

export const selectAppState = (state: AppServiceRootState): AppServiceState => state.app

