import styled from 'styled-components'
import color from 'color'

import { COLORS } from '@probatix/common/constants/colors'
import { MOBILE_WIDTH } from '@probatix/common/constants/design'
import { typeByObject } from '@probatix/common/utils/typescript'

export const BANNER_VARIANTS = {
  BLUE: 'blue',
  GRAY: 'gray',
  GREEN: 'green',
  RED: 'red',
  YELLOW: 'yellow',
}

interface StyledBannerProps {
  $variant?: typeByObject<typeof BANNER_VARIANTS>
}

export const StyledBanner = styled.div<StyledBannerProps>`
  border-radius: 9px;
  padding: 0.875rem;
  font-size: 18px;
  line-height: 0.875rem;
  background: ${COLORS.GRAY_LIGHT};
  border: 1px solid ${COLORS.BLUE_LIGHT};
  color: ${COLORS.BLACK};
  
  ${(props) => ((props.$variant) && BANNER_VARIANTS.BLUE === (props.$variant)) && `
    background: ${COLORS.BLUE};
    border: 1px solid ${COLORS.BLUE_LIGHT};
    color: ${COLORS.WHITE};
  `};

  ${(props) => ((props.$variant) && BANNER_VARIANTS.RED === (props.$variant)) && `
    background: ${COLORS.AMARANTH};
    border: 1px solid ${COLORS.AMARANTH};
    color: ${COLORS.DARK_SHADE_OF_GRAY};
  `};

  ${(props) => ((props.$variant) && BANNER_VARIANTS.GREEN === (props.$variant)) && `
    background: ${color(COLORS.GREEN).alpha(0.06)};
    border: 1px solid ${color(COLORS.GREEN).alpha(0.06)};
    color: ${COLORS.DARK_SHADE_OF_GRAY};
  `};

  ${(props) => ((props.$variant) && BANNER_VARIANTS.YELLOW === (props.$variant)) && `
    background: ${COLORS.YELLOW};
    border: 1px solid ${COLORS.YELLOW};
    color: ${COLORS.DARK_SHADE_OF_GRAY};
  `};
  
  @media(max-width: ${MOBILE_WIDTH}px) {
    border-radius: 0;
    margin: auto;
  }
`
