import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import { Nullable } from '@probatix/common/models/global'
import { Customer } from '@probatix/common/services/RTKServices/patients/index.model'
import { SubmissionError } from './testsService.model'

export const isAnyPersonSelected = (person: Nullable<Customer>): boolean => !!person?.selected

export const hasSubmissionError = (error: SubmissionError): boolean => error.main
  || !error.additionalPersonIndexes.length

export const isPersonalIdSetForPersons = (person: Nullable<Customer>): SubmissionError => {
  const error: SubmissionError = { additionalPersonIndexes: [], main: false }
  if (person?.selected && !person?.personalIdNumber) {
    error.main = true
  }

  return error
}

export const isInsurancePolicyIdSetForPersons = (person: Nullable<Customer>): SubmissionError => {
  const error: SubmissionError = { additionalPersonIndexes: [], main: false }

  if (person?.selected && !person?.healthInsurancePolicyNumber) {
    error.main = true
  }

  return error
}

export const isAddressSetForPersons = (person: Nullable<Customer>): SubmissionError => {
  const error: SubmissionError = { additionalPersonIndexes: [], main: false }

  if (person?.selected && !person?.addressCity
    && !person?.addressCountry
    && !person?.addressStreetAndHouseNumber
    && !person?.addressZipCode) {
    error.main = true
  }

  return error
}

export const isPhoneNumberSetForPersons = (person: Nullable<Customer>): SubmissionError => {
  const error: SubmissionError = { additionalPersonIndexes: [], main: false }

  if (person?.selected && !person?.phoneNumber) {
    error.main = true
  }

  return error
}

export const isInsuranceCompanyIdSetForPersons = (person: Nullable<Customer>): SubmissionError => {
  const error: SubmissionError = { additionalPersonIndexes: [], main: false }

  if (person?.selected && !person?.healthInsuranceCompanyNumber) {
    error.main = true
  }

  return error
}

export const getSelectedPersonsFirstNames = (person: Nullable<Customer>): string[] => {
  const selectedPersons: string[] = []

  if (person?.selected) {
    selectedPersons.push(person?.firstName)
  }

  return selectedPersons
}

export const isMinAgeSetForPersons = (person: Nullable<Customer>, minAge: number): SubmissionError => {
  const error: SubmissionError = { additionalPersonIndexes: [], main: false }
  const today = new Date()

  if (person?.selected) {
    const { dateOfBirth } = person
    error.main = minAge > differenceInCalendarYears(today, new Date(dateOfBirth))
  }

  return error
}
