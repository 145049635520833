import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  getCoreDomain,
  customHeaders,
} from '@probatix/common/services/services.helpers'
import {
  basePathLocations, myProbatixActive, noPagination,
} from '@probatix/common/services/RTKServices/locations/index.helpers'
import { Location } from './index.model'

// Define a service using a base URL and expected endpoints
export const locationsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: getCoreDomain() }),
  endpoints: (builder) => ({
    getHomeLocation: builder.query<any, any>({
      query: ({ customEntryPoint }) => ({
        headers: customHeaders(),
        method: 'GET',

        url: `${basePathLocations(customEntryPoint)}?page=1&tenant.isTestAtHomeInstance=1`,
      }),
    }),
    getUnGroupedLocation: builder.query<any, any>({
      query: ({ baseProductUuid, customEntryPoint, location }) => ({
        headers: customHeaders(),
        method: 'GET',
        /* eslint-disable-next-line max-len */
        url: `${basePathLocations(customEntryPoint)}/${location}${baseProductUuid ? `?baseProductUuid=${baseProductUuid}&${myProbatixActive}` : `?${myProbatixActive}`}`,
      }),
      transformResponse: (responseData: Location) => ({ ...responseData, distance: null })
      ,
    }),
    getUnGroupedLocations: builder.query<any, any>({
      query: ({ baseProductUuid, customEntryPoint, isQuickOrder }) => ({
        headers: customHeaders(),
        method: 'GET',
        /* eslint-disable-next-line max-len */
        url: `${basePathLocations(customEntryPoint)}${baseProductUuid ? `?baseProductUuid=${baseProductUuid}&${myProbatixActive}&${noPagination}` : `?${myProbatixActive}`}&${noPagination}${isQuickOrder ? '&tenant.contractType=fullyManaged' : ''}`,
      }),
      transformResponse: (responseData: Location[]) => responseData.map((obj) => ({ ...obj, distance: null }))
      ,
    }),
  }),
  reducerPath: 'locationsApi',
})

export const useGetHomeLocation = locationsApi.endpoints.getHomeLocation.useQuery
export const useGetUnGroupedLocations = locationsApi.endpoints.getUnGroupedLocations.useQuery
export const useGetUnGroupedLocationsLazy = locationsApi.endpoints.getUnGroupedLocations.useLazyQuery
export const useGetUnGroupedLocation = locationsApi.endpoints.getUnGroupedLocation.useLazyQuery
