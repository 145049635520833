import { createSelector } from 'reselect'

import { Translation, TranslationsRootState, TranslationsState } from './translationsService.model'

export const getTranslationsState = (state: TranslationsRootState): TranslationsState => state.translations

export const selectTranslationByLocale = createSelector(
  [
    getTranslationsState,
    (_, params: { locale: string }) => params,
  ],
  (state, params): Translation => {
    const { locale } = params

    if (state.data?.[locale]) {
      return state.data[locale]
    }

    return {
      data: null,
      errors: false,
      fetching: false,
      initialized: false,
    }
  },
)

export const selectTranslationsData = createSelector(
  [getTranslationsState],
  (state) => state.data,
)
