import { getCurrentLocale } from '@probatix/common/translations'

import SessionStorage from '@probatix/common/utils/sessionStorage'
import format from 'date-fns/format'
import { transformPatientEventsIntoGuestBooking } from './RTKServices/patientEvents/index.helpers'

import { CORE_DOMAIN, JWT } from './services.constants'

export const ENV: string = process.env.REACT_APP_APP_ENV || 'local'

export const CORE_API_ENTRYPOINT = process.env.REACT_APP_CORE_API_ENTRYPOINT || 'https://api.probatix.de'

export const getCoreApiUrl = (coreDomain: string) => {
  if ('local' === ENV) {
    return 'http://dev.probatix.localhost/'
  }

  if ('' === coreDomain) {
    return `${CORE_API_ENTRYPOINT}/api/`
  }

  return `https://${coreDomain}.probatix.de/`
}

export const getCurrentCoreDomain = () => getCoreApiUrl('')

/*+++*/

export const getCoreDomain = () => SessionStorage.get(CORE_DOMAIN) // old getSessionStorageCoreDomain

export const getStorageCoreDomain = () => getCoreApiUrl(getCoreDomain()) // old getCurrentCoreDomain

export const saveAndUseCoreDomain = (coreDomain: string) => {
  const sessionStorageCoreDomain = getCoreDomain()

  if (sessionStorageCoreDomain && 'null' !== sessionStorageCoreDomain) {
    return getCoreApiUrl(sessionStorageCoreDomain)
  }

  SessionStorage.set(CORE_DOMAIN, coreDomain)

  return getCoreApiUrl(coreDomain)
}

/* parse jwt token */
export const parseStorage = (key) => {
  const tempData = JSON.parse(SessionStorage.get(key))
  if (!!tempData && !!tempData.expiry && !!tempData.value) {
    return { expiry: tempData.expiry, value: tempData.value }
  }

  return { expiry: '', value: 0 }
}

export const removeToken = ({ expiryKey, key }: { expiryKey: string, key: string }) => {
  SessionStorage.remove(key)
  SessionStorage.remove(expiryKey)
}

export const getToken = ({ expiryKey, key }: { expiryKey: string, key: string }) => {
  const token = SessionStorage.get(key)
  const expiry = SessionStorage.get(expiryKey)

  if (token && expiry && "undefined" !== token) {
    const currentTime = new Date().getTime()
    if (currentTime < Number(expiry)) {

      return token

    } else {
      removeToken({ expiryKey, key })

      return null
    }
  }

  removeToken({ expiryKey, key })

  return null
}

export const authPatch = () => {
  const headers = {
    'Accept-Language': getCurrentLocale().code.slice(0, 2),
  }

  const token = SessionStorage.get(JWT)
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return headers
}

export const headers = () => ({
  ...{
    Accept: 'application/json',
    'Accept-Language': getCurrentLocale().code.slice(0, 2),
  },
})

export const customHeaders = () => ({
  ...authPatch(),
  ...{
    Accept: 'application/json',
  },
})

export const customHeadersJSON = () => ({
  ...authPatch(),
  ...{
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const customHeadersLD = () => ({
  ...authPatch(),
  ...{
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  },
})

/* ./ parse jwt token */
// return domain for downloadable files
export const returnLinkForDownload = (baseUrl) => {
  let core = baseUrl.replace('.probatix.de', '')
  core = core.replace(/(http:\/\/|https:\/\/)/g, '')

  return core
}

// return domain for downloadable files
export const singleTransformedTest = (data) => {
  let tempData = data
  if (null !== data && data?.testCenter?.baseUrl) {
    SessionStorage.set(CORE_DOMAIN, returnLinkForDownload(data.testCenter.baseUrl))
    tempData = transformPatientEventsIntoGuestBooking(data)
  }

  return tempData
}

export const returnDateOfBirth = ({ dateOfBirth, formatDate }: { dateOfBirth: any, formatDate?: string }) => {
  try {
    let day: string; let month: string; let
      year: string

    if ('string' === typeof dateOfBirth && dateOfBirth.includes('-') && 11 > dateOfBirth.toString().length) {
      // Assuming dateOfBirth is in the format "DD-MM-YYYY"
      [day, month, year] = dateOfBirth.split('-')
    } else if ('string' === typeof dateOfBirth) {
      // Assuming dateOfBirth is in the format "DD-MM-YYYYhhmmss...."
      const dateObject = new Date(dateOfBirth)
      day = String(dateObject.getDate())
      month = String(dateObject.getMonth() + 1) // Adding 1 because months are 0-based
      year = String(dateObject.getFullYear())
    } else {
      // Handle the case where dateOfBirth is already a Date object
      const dateObject = new Date()
      day = String(dateObject.getDate())
      month = String(dateObject.getMonth() + 1) // Adding 1 because months are 0-based
      year = String(dateObject.getFullYear())
    }

    const formattedDate = `${year}-${month}-${day}`
    if (!formatDate) {
      return formattedDate
    }

    return format(new Date(formattedDate), formatDate)
  } catch (error) {
    if (!formatDate) {
      return dateOfBirth
    }

    return format(new Date(dateOfBirth), formatDate)
  }
}
