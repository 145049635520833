import format from 'date-fns/format'

import { Nullable } from '@probatix/common/models/global'

import {
  AdditionalPerson,
  Customer,
  PersonalAddress,
  User,
} from '@probatix/common/services/RTKServices/patients/index.model'

import {
  Patient,
} from './userService.model'

export const transformUserToCustomer = (user: User, selected?: boolean): Customer => {

  const customer = {
    addressCity: user.addressCity,
    addressCountry: user.addressCountry,
    addressStreetAndHouseNumber: user.addressStreetAndHouseNumber,
    addressZipCode: user.addressZipCode,
    dateOfBirth: format(new Date(user.dateOfBirth), 'yyyy-MM-dd\'T\'HH:mm:ss'),
    email: user.email,
    firstName: user.firstName,
    gender: user.gender,
    healthInsuranceCompanyNumber: user.healthInsuranceCompanyNumber,
    healthInsurancePolicyNumber: user.healthInsurancePolicyNumber,
    lastName: user.lastName,
    personalIdNumber: user.personalIdNumber,
    phoneNumber: user.phoneNumber,
    selected: !!selected,
  }

  return customer
}

export const transformAuthenticatedUserToCustomer = (user: User, selected?: boolean): Customer => {
   
  const { ...customerProps } = user

  return {
    ...customerProps,
    addressCity: customerProps.city,
    addressCountry: customerProps.country,
    addressStreetAndHouseNumber: customerProps.streetAndHouseNumber,
    addressZipCode: customerProps.zipCode,
    selected: !!selected,
  }
}

export const transformFormUserToUser = <T extends Customer | Patient>(formUser: T): User => {
  const { addressCity, addressCountry, addressStreetAndHouseNumber, addressZipCode, dateOfBirth, ...other } = formUser

  return {
    ...other,
    city: addressCity,
    country: addressCountry,
    dateOfBirth: format(new Date(dateOfBirth), 'dd-MM-yyyy'),
    streetAndHouseNumber: addressStreetAndHouseNumber,
    zipCode: addressZipCode,
  }
}

export const transformUserToAdditionalPerson = (user: User, selected: boolean): AdditionalPerson => ({
  address: {
    addressCity: user.city,
    addressCountry: user.country,
    addressStreetAndHouseNumber: user.streetAndHouseNumber,
    addressZipCode: user.zipCode,
  },
  selected,
  uuid: user.uuid,
  values: {
    dateOfBirth: user.dateOfBirth,
    email: user.email,
    firstName: user.firstName,
    gender: user.gender,
    healthInsuranceCompanyNumber: user.healthInsuranceCompanyNumber,
    healthInsurancePolicyNumber: user.healthInsurancePolicyNumber,
    lastName: user.lastName,
    personalIdNumber: user.personalIdNumber,
    phoneNumber: user.phoneNumber,
  },
})

export const transformMainPersonToAdditionalContact = (mainPerson: Customer): AdditionalPerson => ({
  address: {
    addressCity: mainPerson.addressCity,
    addressCountry: mainPerson.addressCountry,
    addressStreetAndHouseNumber: mainPerson.addressStreetAndHouseNumber,
    addressZipCode: mainPerson.addressZipCode,
  },
  selected: true,
  values: {
    dateOfBirth: mainPerson.dateOfBirth,
    email: mainPerson.email,
    firstName: mainPerson.firstName,
    gender: mainPerson.gender,
    healthInsuranceCompanyNumber: mainPerson.healthInsuranceCompanyNumber,
    healthInsurancePolicyNumber: mainPerson.healthInsurancePolicyNumber,
    lastName: mainPerson.lastName,
    personalIdNumber: mainPerson.personalIdNumber,
    phoneNumber: mainPerson.phoneNumber,
  },
  wasMainContact: true,
})

export const transformAdditionalContactToMainPerson = (person: AdditionalPerson): Customer => ({
  ...person.address,
  ...person.values,
})

export const isAddressSet = <T extends Customer | Patient | PersonalAddress>(data: Nullable<T>): boolean => {
  if (!data) {
    return false
  }

  const { addressCity, addressCountry, addressStreetAndHouseNumber, addressZipCode } = data

  return !!(addressCountry && addressCity && addressZipCode && addressStreetAndHouseNumber)
}

export const isBillingAddressSet = <T extends Customer>(data: Nullable<T>): boolean => {
  if (!data) {
    return false
  }

  const { billingAddressCity, billingAddressCountry, billingAddressStreet, billingAddressZipCode } = data

  return !!(billingAddressCity && billingAddressCountry && billingAddressStreet && billingAddressZipCode)
}

export const transformPersonalAddressToAddress = <T extends PersonalAddress | Customer>(person: T) => ({
  city: person.addressCity,
  country: person.addressCountry,
  streetAndHouseNumber: person.addressStreetAndHouseNumber,
  zipCode: person.addressZipCode,
})
