import { combineReducers } from 'redux'

import userListService from './list/userListService'
import * as listSelectors from './list/userListService.selectors'
import * as listActions from './list/userListService.actions'

import userSingleService from './single/userSingleService'
import * as singleSelectors from './single/userSingleService.selectors'
import * as singleActions from './single/userSingleService.actions'

import * as helpers from './userService.helpers'

export const userServiceActions = {
  ...singleActions,
  ...listActions,
}

export const userServiceSelectors = {
  ...singleSelectors,
  ...listSelectors,
}

export const userServiceHelpers = {
  ...helpers,
}

export const userServiceReducers = combineReducers({
  list: userListService,
  single: userSingleService,
})
