import { combineReducers } from 'redux'

import availabilitiesListService from './list/availabilitiesListService'
import * as listSelectors from './list/availabilitiesListService.selectors'
import * as listActions from './list/availabilitiesListService.actions'

export const availabilitiesServiceActions = {
  ...listActions,
}

export const availabilitiesServiceSelectors = {
  ...listSelectors,
}

export const availabilitiesServiceReducers = combineReducers({
  list: availabilitiesListService,
})

