import { PayloadAction } from '@reduxjs/toolkit'

import { getBasicListInitialState, getBasicListReducerSlice } from '@probatix/common/utils/service'

import { User, UsersListState } from '@probatix/common/services/RTKServices/patients/index.model'
import { STATE_KEY } from '../userService.constants'

export const slice = getBasicListReducerSlice<User[]>(getBasicListInitialState(), STATE_KEY, {
  setUsers: (list: UsersListState, action: PayloadAction<User[]>) => {
    list.data = action.payload
    list.fetching = false
    list.errors = null
  },
})

export default slice.reducer
