import styled from 'styled-components'
import { COLORS } from '@probatix/common/constants/colors'

export const StyledDescription = styled.span`
  line-height: 1.5em;

  & > a {
    color: ${COLORS.BLUE_LIGHT};
    text-decoration: underline !important;
  }
`

