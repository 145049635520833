import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '@probatix/common/models/global'

import { Customer } from '@probatix/common/services/RTKServices/patients/index.model'
import { Product } from '@probatix/common/services/RTKServices/checkoutSteps/checkoutStepsService.model'

import { FlowType, TestsFormServiceState } from '../testsService.model'

const initialState: TestsFormServiceState = {
  baseProduct: null,
  checkedProduct: false,
  crossOutPrice: false,
  enabledTest: false,
  explode: false,
  flow: FlowType.MAIN,
  location: null,
  person: null,
  product: null,
  publicCheckout: false,
  store: null,
  time: null,
}

export default createSlice({
  initialState,
  name: 'testForm',
  reducers: {
    loadData: (state, { payload }: { payload: TestsFormServiceState }) => payload,
    removeMainPerson: (state) => {
      state.person = null
    },
    reset: (state, { payload }: {
      payload: {
        baseProduct: boolean,
        checkedProduct: boolean,
        crossOutPrice: boolean,
        location: boolean,
        person: boolean,
        product: boolean,
        store: boolean,
        time: boolean
      }
    }) => {
      const { baseProduct, checkedProduct, crossOutPrice, location, person, product, store, time } = payload

      state.baseProduct = baseProduct ? null : state.baseProduct
      state.crossOutPrice = crossOutPrice ? false : state.crossOutPrice
      state.checkedProduct = checkedProduct ? false : state.checkedProduct
      state.location = location ? null : state.location
      state.product = product ? null : state.product
      state.time = time ? null : state.time
      state.store = store ? null : state.store
      state.person = person ? null : state.person
    },
    saveBaseProduct: (state, { payload }: { payload: { baseProduct: Nullable<Product> } }) => {
      state.baseProduct = payload.baseProduct
    },
    saveCheckedProduct: (state, { payload }: { payload: { checkedProduct: boolean } }) => {
      state.checkedProduct = payload.checkedProduct
    },
    saveCrossOutPrice: (state, { payload }: { payload: { crossOutPrice: boolean } }) => {
      state.crossOutPrice = payload.crossOutPrice
    },
    saveEnabledTest: (state, { payload }: { payload: { enabledTest: boolean } }) => {
      state.enabledTest = payload.enabledTest
    },
    saveExploding: (state, { payload }: { payload: { explode: boolean } }) => {
      state.explode = payload.explode
    },
    saveFlow: (state, { payload }: { payload: { flow: FlowType } }) => {
      state.flow = payload.flow
    },
    saveLocation: (state, { payload }: { payload: { location: any } }) => {
      state.location = payload.location
    },
    saveMainPerson: (state, action: PayloadAction<Customer>) => {
      state.person = action.payload
    },
    saveProduct: (state, { payload }: { payload: { product: Nullable<Product> } }) => {
      state.product = payload.product
    },
    savePublicCheckout: (state, { payload }: { payload: { publicCheckout: boolean } }) => {
      state.publicCheckout = payload.publicCheckout
    },
    saveStore: (state, { payload }: { payload: { store: Nullable<any> } }) => {
      state.store = payload.store
    },
    saveTime: (state, { payload }: { payload: { time: Nullable<Date> } }) => {
      state.time = payload.time
    },
  },
})
