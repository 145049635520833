import axios from 'axios'

import { PayloadAction } from '@reduxjs/toolkit'

import { HEADERS } from '@probatix/common/constants/http'

import { getSubdomain } from '@probatix/common/utils/url'
import { ActionParams } from '@probatix/common/utils/service'

import { apiCallInitialized } from './apiService.actions'
import { getBaseUrl } from './apiService.selectors'

type Action = PayloadAction<ActionParams<object>>

interface ApiMiddlewareProps {
  apiPrefix?: string
  entrypoint?: string
  i18n?: any
  overrideResponseData?: object
  withCredentials?: boolean,
  withRelativeUrl?: boolean,
  withSubdomain?: boolean
}

const apiServiceMiddleware = ({
  apiPrefix,
  entrypoint,
  i18n,
  withCredentials,
  withRelativeUrl,
  withSubdomain,
 
}: ApiMiddlewareProps) => ({ dispatch, getState }) => (next) => async (action: Action) => {

  let effectiveSubdomain = null
  if (withSubdomain) {
    const { app } = getState()
    const { subdomain } = app

    // NOTE: App useEffect is not triggered first, that is why sometimes subdomain is not set yet
    effectiveSubdomain = subdomain || getSubdomain()

    if (!effectiveSubdomain) {
      window.location.href = '/not-found'

      return false
    }
  }

  if (!apiCallInitialized.match(action)) {
    const results = next(action)

    if (action.payload?.onSuccessCallback) {
      const { onSuccessCallback } = action.payload as any

      if (onSuccessCallback) {
        onSuccessCallback()
      }
    }

    return results
  }

  const {
    customApiPrefix,
    customEntrypoint,
    customHeaders,
    data,
    meta,
    method,
    onError,
    onErrorCallback,
    onFinallyCallback,
    onInit,
    onSuccess,
    onSuccessCallback,
    silent,
    url,
    withCredentials: overrideWithCredentials,
    // NOTE: define model
  } = action.payload as any

  if (onInit) {
    dispatch({ meta, silent, type: onInit })
  }

  next(action)

  try {
    const locale = customHeaders?.[HEADERS.ACCEPT_LANGUAGE] || i18n?.language

    let headers = {
      [HEADERS.ACCEPT]: 'application/json',
      [HEADERS.CONTENT_TYPE]: 'application/json',
    }

    if (locale) {
      headers[HEADERS.ACCEPT_LANGUAGE] = locale
    }

    if (customHeaders) {
      headers = {
        ...headers,
        ...customHeaders,
      }
    }

    const base = getBaseUrl({
      apiPath: customApiPrefix || '' === customApiPrefix ? customApiPrefix : apiPrefix,
      customEntrypoint: !!customEntrypoint,
      entrypoint: customEntrypoint || entrypoint,
      subdomain: effectiveSubdomain,
      withRelativeUrl,
    })

    let includeCredentials = false
    if (undefined !== overrideWithCredentials) {
      includeCredentials = overrideWithCredentials
    } else if (undefined !== withCredentials) {
      includeCredentials = withCredentials
    }

    const response = await axios.request({
      baseURL: base,
      data,
      headers,
      method,
      url,
      withCredentials: includeCredentials,
    })

    if (onSuccess) {
      /*
      sc-29297: due to a change on backend side
      */
      const overrideData = action.payload?.overrideResponseData
      const responseData = overrideData ? { ...response.data, ...overrideData } : response.data
      dispatch({ meta, payload: responseData, silent, type: onSuccess })
    }

    if (onSuccessCallback) {
      onSuccessCallback(response.data)
    }
  } catch (error: any) {
    if (onError) {
      dispatch({
        meta,
        payload: {
          ...error?.response?.data,
          status: error?.response?.status,
        },
        silent,
        type: onError,
      })
    }

    if (onErrorCallback) {
      onErrorCallback(error?.response?.data)
    }
  } finally {
    if (onFinallyCallback) {
      onFinallyCallback()
    }
  }
}

export default apiServiceMiddleware
