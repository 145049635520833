import { combineReducers } from 'redux'

import testsFormService from './form/testsFormService'
import * as selectors from './form/testsFormService.selectors'
import * as helpers from './testsFormService.helpers'

export const testsServiceActions = {
  ...testsFormService.actions,
}

export const testsServiceSelectors = {
  ...selectors,
}

export const testsServiceHelpers = {
  ...helpers,
}

export const testsServiceReducers = combineReducers({
  form: testsFormService.reducer,
})
