import { combineReducers } from 'redux'

import modalCommonService, { ModalCommonServiceState } from './common/modalCommonService'
import * as selectors from './common/modalCommonService.selectors'

export const modalServiceActions = {
  ...modalCommonService.actions,
}

export const modalServiceSelectors = {
  ...selectors,
}

export const modalServiceReducers = combineReducers({
  common: modalCommonService.reducer,
})

export interface ModalServiceState {
  common: ModalCommonServiceState
}

export interface ModalRootState {
  modal: ModalServiceState
}
