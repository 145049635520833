import { sha256 } from 'js-sha256'

/* eslint-disable camelcase */
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
  fbq: (
    eventType:string,
    eventName:string,
    purchaseObject: Object,
    eventObject: Object
  ) => void
}

declare const window: WindowWithDataLayer

const useGTManager = () => {
  const pushDataLayer = (event: Object) => {
    try {
      window.dataLayer.push(event)
      // eslint-disable-next-line no-empty
    } catch (error) { }
  }

  const postFacebookPurchaseEvent = (event: {email, paidPrice, patientUuid, quickOrderUuid}) => {
    try {
      window.fbq('track', 'Purchase',
        {
          currency: 'EUR',
          value: event.paidPrice,
        },
        {
          eventID: event.quickOrderUuid,
          userData: {
            em: sha256(event.email),
            externalId: sha256(event.patientUuid),
          },
        },
      )
      // eslint-disable-next-line no-empty
    } catch (error) { }
  }

  const customPosthog = (title: string, event: Object) => { // ++
    try {
      posthog.capture(title, event)
    } catch { }// eslint-disable-line
  }

  const customPosthogIdentify = (title: string) => {
    try {
      posthog.identify(`${title}`)
    } catch { } // eslint-disable-line
  }

  const featureFlag = (): boolean => {
    try {
      posthog.onFeatureFlags(function () {
        if ('control' === posthog.getFeatureFlag('quick-checkout-variants')) {
          posthog.capture('$feature_view', { feature_flag: "control" })

          return false
        }
      })

      posthog.reloadFeatureFlags()

      if ('new-variant' === posthog.getFeatureFlag('quick-checkout-variants')) {
        posthog.capture('$feature_view', { feature_flag: 'new-variant' })

        return true
      }

      return false
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error generating feature-flag:', error)

      return false
    }
  }

  return {
    customPosthog,
    customPosthogIdentify,
    featureFlag,
    postFacebookPurchaseEvent,
    pushDataLayer,
  }
}

export default useGTManager
