import { Error } from '@probatix/common/models/global'
import { decamelize } from '@probatix/common/utils/data'
import { isLocaleSupported } from 'translations'

export const getLocale = (pathname, i18n) => {
  const match = pathname.match(/\/(?<locale>[a-z]{2})\/.*/)
  let locale = match?.groups?.locale

  if (!locale || !isLocaleSupported(locale)) {
    locale = i18n.language
  }

  if (locale !== i18n.language) {
    i18n.changeLanguage(locale)
  }

  return locale || i18n.language
}

export const getDecimalizedErrorMessage = (err: Error) => (err.violations?.length
  ? err.violations.map((v) => `${decamelize(v.propertyPath)}: ${v.message}`)
  : [decamelize(err.detail! || err.title!)])
