import styled from 'styled-components'

import React from 'react'

import { SpaceProps } from './Space.model'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledSpace = styled(({ inline, margin, padding, space, ...props }) => (
  <div {...props} />
))<SpaceProps>`
  ${({ margin }) => margin && `
    margin: ${margin};
  `}
  
  ${({ padding }) => padding && `
    padding: ${padding};
  `}
  
  ${({ space }) => space && `
    margin-bottom: ${space};
  `}  
  
  ${({ inline }) => inline && `
    display: inline;
  `}
`
