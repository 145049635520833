import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'
import { typeByObject } from '@probatix/common/utils/typescript'

export const SELECT_VERSION = {
  SMALL: 'small',
}

interface IStyledSelect {
  $error?: boolean,
  $padding?: string,
  $version?: typeByObject<typeof SELECT_VERSION>,
  checkout: boolean
}

interface IStyledArrowPlaceholder {
  $hasValue?: boolean,
  $version?: typeByObject<typeof SELECT_VERSION>,
  checkout: boolean
}

export const StyledSelect = styled.select<IStyledSelect>`
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.CADET_BLUE};
  width: 100%;
  padding: 25px 1.375rem 5px 0.94rem;
  border-radius: 0.3125rem;
  ${({ checkout }) => checkout && `
      height: 4rem;
      border-radius: 1.375rem;
      margin-bottom: 0px !important;
  `}
  appearance: none;
  position: relative;
  font-size: 0.8rem;
  line-height: 1.1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ $version }) => SELECT_VERSION.SMALL === $version && `
    border-width: 1px;
    border-color: #D9D9D9 !important;
    border-radius: 7px;
    background: ${COLORS.WHITE};
    padding: 10px 40px 10px 10px;
  `}
   
  ${({ $error }) => $error && `
    border-color: ${COLORS.ERROR} !important;
  `}

  ${({ $padding }) => $padding && `
    padding: ${$padding} !important;
  `}
`

export const StyledSelectWrapper = styled.div`
  position: relative;
`

export const StyledArrow = styled.div<IStyledArrowPlaceholder>`
  position: absolute;
  right: 1.3rem;
  z-index: 10;
  top: 1.2rem;
  transform: translateY(-50%);
  height: 0.875rem;
  

  ${({ $hasValue }) => !!$hasValue && `
    top: 1.6rem;
  `}

  ${({ $hasValue, checkout }) => checkout && !!$hasValue && `
     top: 2rem;
  `}

  ${({ $hasValue, checkout }) => checkout && !$hasValue && `
     top: 1.6rem;
  `}
  
  ${({ $version }) => $version === SELECT_VERSION.SMALL && `
    top: 18px;
    transform: translateY(0);
  `}
  
  ${({ $hasValue, $version }) => !$hasValue && $version !== SELECT_VERSION.SMALL && `
    transform: translateY(0);
  `}
`

export const StyledPlaceholder = styled.div<IStyledArrowPlaceholder>`
  position: absolute;
  left: 1.05rem;
  top: 10px;
  font-size: 0.625rem;
  pointer-events: none;
  color: ${COLORS.CADET_BLUE};
  
  ${({ $hasValue }) => !$hasValue && `
    top: 1.1rem;
    font-size: 0.8rem;
  `}

  ${({ $hasValue, checkout }) => checkout && !!$hasValue && `
     top: 0.9rem;
  `}

  ${({ $hasValue, checkout }) => checkout && !$hasValue && `
     top: 1.6rem;
  `}
  
  
  ${({ $hasValue, $version }) => !$hasValue && $version === SELECT_VERSION.SMALL && `
    top: 0.875rem;
    left: 10px;
  `}
`
