import React from 'react'

import { NullableString } from '@probatix/common/models/global'

import Image from '../Image'

interface LogoProps {
  height?: number
  margin: NullableString
  src: string
  width?: number
}

const Logo: React.FC<LogoProps> = (props:LogoProps) => <Image {...props} />

export default Logo
