import { Normalize } from 'styled-normalize'

import React from 'react'

import '@probatix/common/styled/fonts.css'
import 'react-spring-bottom-sheet/dist/style.css'

import { IconsStyle } from '@probatix/common/styled/icons.styled'
import { GlobalStyled } from './global'

const Styled = () => (
  <React.Fragment>
    <Normalize />
    <IconsStyle />
    <GlobalStyled />
  </React.Fragment>
)

export default Styled
