export interface EventBusInterface {
  clear: () => void;
  dispatch: (event: string, data: any) => void;
  on: (event: string, callback: Function) => void;
  remove: (event: string, callback?: Function) => void;
}

const eventMap: Map<string, Function[]> = new Map()

const eventBus: EventBusInterface = {
  clear() {
    eventMap.forEach((handlers, event) => {
      handlers.forEach((handler) => {
        document.removeEventListener(event, handler as EventListener)
      })
    })
    eventMap.clear()
  },
  dispatch(event, data: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }))
  },
  on(event, callback) {
    const handler = (e: any) => callback(e.detail)

    if (!eventMap.has(event)) {
      eventMap.set(event, [])
    }

    eventMap.get(event)?.push(handler)
    document.addEventListener(event, handler)
  },
  remove(event, callback?) {
    const handlers = eventMap.get(event)

    if (handlers) {
      if (callback) {
        const handlerIndex = handlers.findIndex((h) => h === callback)
        if (-1 !== handlerIndex) {
          document.removeEventListener(event, handlers[handlerIndex] as EventListener)
          handlers.splice(handlerIndex, 1)
        }
      } else {
        handlers.forEach((handler) => {
          document.removeEventListener(event, handler as EventListener)
        })
        eventMap.delete(event)
      }
    }
  },
}

export default eventBus