import { NullableString } from '@probatix/common/models/global'

import { init } from '@probatix/common/translations'

import { SUPPORTED_LOCALE } from '@probatix/common/utils/data'

import { DEFAULT_LOCALE } from '@probatix/checkout/src/config/app'

import Trans from './components/Trans'

const i18n = init(
  {},
  DEFAULT_LOCALE || 'de',
)

export const isLocaleSupported = (locale: NullableString) => locale && 0 <= SUPPORTED_LOCALE.indexOf(locale)

export { Trans }
export default i18n
