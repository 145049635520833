import { createSelector } from 'reselect'

import { AvailableDaysCollection, AvailableDaysRootState } from '../availableDaysService.model'

export const getAvailableDaysState = (state: AvailableDaysRootState) => state.availableDays

export const getAvailableDaysListState = (state: AvailableDaysRootState) => state.availableDays.list

export const selectAvailableDaysList = createSelector(
  [getAvailableDaysListState],
  (listState): AvailableDaysCollection => ({
    // NOTE: workaround for the duplicates sent by API
    data: listState.data?.available_days?.length ? [...new Set(listState.data.available_days)] : [],
    errors: listState.errors,
    fetching: listState.fetching,
    initialized: listState.initialized,
  }),
)
