import React from 'react'

import { StyledContainer, StyledSpinner, StyledSpinnerAnimation } from './Spinner.styled'

interface SpinnerProps {
  display?: 'inline'
  size?: 'small' | 'internal'
  variant?: 'white'
}

const Spinner: React.FC<SpinnerProps> = (props) => (
  <StyledContainer {...props} data-qa="spinner" data-testid="spinner">
    <StyledSpinner {...props}>
      <StyledSpinnerAnimation {...props} />
    </StyledSpinner>
  </StyledContainer>
)

export default Spinner
