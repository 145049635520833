import { NullableString } from '@probatix/common/models/global'

export const SUPPORTED_LOCALE: string[] = ['de', 'en', 'fr']

export const checkArrayItems = (array = [], items = []) => items.some((item) => array.includes(item))

export const generateIcsUrl = (icsLink: string) => `data:text/calendar;charset=utf8,${encodeURIComponent(icsLink)}`

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
export const decamelize = (str: string, separator: string = ' ', capitaliseFirst: boolean = true): NullableString => {
  if (!str) {
    return null
  }

  const text = str
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`)
    .toLowerCase()

  return capitaliseFirst ? capitalizeFirstLetter(text) : text
}

export const getDecimalizedErrorMessage = (err: any) => (err.violations?.length
  ? err.violations.map((v) => `${v.message}`)
  : [decamelize(err.detail! || err.title!)])

export const isLocaleSupported = (locale: NullableString) => locale && 0 <= SUPPORTED_LOCALE.indexOf(locale)

export const getLocale = (pathname, i18n) => {
  const match = pathname.match(/\/(?<locale>[a-z]{2})\/.*/)
  let locale = match?.groups?.locale

  if (!locale || !isLocaleSupported(locale)) {
    locale = 'de'
  }

  if (locale !== i18n.language) {
    i18n.changeLanguage(locale)
  }

  return locale || i18n.language
}
