import React from 'react'

import LocationPage from './LocationPage'
import BookTestWrapper from './BookTest.wrapper'

export interface BookTestRoutingParams {
  locationUrlKey: string
  productUrlKey: string
}

const BookTestRouting = [
  {
    children: [
      {
        element: <LocationPage />,
        path: '',
      },
    ],
    element: <BookTestWrapper />,
    path: 'pick-slot',
  },
]

export default BookTestRouting
