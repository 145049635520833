import React, { PropsWithChildren } from 'react'

import GridItem from './GridItem'
import { GridProps } from './Grid.model'
import { StyledGridContainer } from './Grid.styled'

interface GridRoot<T> extends React.FC<T> {
  Item?: typeof GridItem
}

const Grid: GridRoot<PropsWithChildren<GridProps>> = ({
  alignItems,
  alignSelf,
  children,
  gap,
  gridTemplateColumns,
  justifyContent,
  margin,
  maxHeight,
  minHeight,
  padding,
}) => (
  <StyledGridContainer
    alignItems={alignItems}
    alignSelf={alignSelf}
    data-testid="grid-container"
    gap={gap}
    gridTemplateColumns={gridTemplateColumns}
    justifyContent={justifyContent}
    margin={margin}
    maxHeight={maxHeight}
    minHeight={minHeight}
    padding={padding}
  >
    {children}
  </StyledGridContainer>
)

export default Grid
