import { AccordionSummaryProps } from '@mui/material/AccordionSummary'

export enum ExpandType {
  APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
  KIT_ON_MY_WAY = 'KIT_ON_MY_WAY',
  LAB_PROCESSING = 'LAB_PROCESSING',
  PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION',
  RESULT_AVAILABLE = 'RESULT_AVAILABLE',
  SAMPLE_COLLECTED_SENT = 'SAMPLE_COLLECTED_SENT',
  SAMPLE_COLLECTION_RETURN = 'SAMPLE_COLLECTION_RETURN',
  SELECT_TEST_TYPE = 'SELECT_TEST_TYPE',
}

export const StepOrder = {
  [ExpandType.PAYMENT_CONFIRMATION]: 0,
  [ExpandType.SELECT_TEST_TYPE]: 1,
  [ExpandType.APPOINTMENT_SCHEDULED]: 2,
  [ExpandType.KIT_ON_MY_WAY]: 3,
  [ExpandType.SAMPLE_COLLECTED_SENT]: 4,
  [ExpandType.SAMPLE_COLLECTION_RETURN]: 5,
  [ExpandType.LAB_PROCESSING]: 6,
  [ExpandType.RESULT_AVAILABLE]: 7,
}

export interface IColorType {
  bg: string;
  color: string;
  iconBG: string;
}

export enum AccordionColorType {
  BLUE = "BLUE",
  GRAY = "GRAY",
  GREEN = "GREEN"
}

export const AccordionColors: Record<AccordionColorType, IColorType> = {
  [AccordionColorType.BLUE]: { bg: "#F0F6FF", color: "#3061eb", iconBG: "#DCEBFE" },
  [AccordionColorType.GREEN]: { bg: "#EFFDF4", color: "#17A34A", iconBG: "#DCFDE8" },
  [AccordionColorType.GRAY]: { bg: "#F9FAFB", color: "#9DA4B1", iconBG: "#F3F4F6" },
}

export interface IStyledAccordionSummary extends AccordionSummaryProps {
  $bg: string,
  $color: string
}

export interface IStep {
  confirmation?: Object,
  currentStep: ExpandType,
  expanded: any,
  handleChange: any,
  onReload?: any,
  patientEvent?: any,
  productID?: string
  productName?: string
}
