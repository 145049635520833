import { createSelector } from 'reselect'

import { Nullable } from '@probatix/common/models/global'

import { Location } from '@probatix/common/services/RTKServices/locations/index.model'

import { Product } from '@probatix/common/services/RTKServices/checkoutSteps/checkoutStepsService.model'
import { TestTypeProducts, ProductRootState } from '../productService.model'

export const getProductListState = (state: ProductRootState) => state.product.list

export const selectProducts = createSelector(
  [getProductListState],
  (state): Product[] => state.data || [],
)

export const selectProductsByLocation = createSelector(
  [
    selectProducts,
    (_, params: { hiddenProducts: string[], location: Nullable<Location> }) => params,
  ],
  (products, params): TestTypeProducts[] => {
    const { hiddenProducts, location } = params
    if (!location) {
      return []
    }

    const { availableProductTypes } = location
    const testTypeProducts: TestTypeProducts[] = []

    Object.entries(availableProductTypes).forEach((entry: [string, string[]]) => {
      const testTypeProduct: TestTypeProducts = { id: entry[0], products: [], testType: entry[0] }
      const productsUuid = entry[1]

      productsUuid.forEach((uuid) => {
        if (!products?.length) {
          return
        }

        const product = products.find((p) => p.uuid === uuid)
        if (product) {
          if (product.isShowProductOnFrontend) {
            testTypeProduct.products.push(product)

            return
          }

          if (hiddenProducts.includes(product.uuid)) {
            testTypeProduct.products.push(product)
          }
        }
      })

      if (testTypeProduct.products.length) {
        const sortedProducts = testTypeProduct.products.sort((a, b) => a.grossPrice - b.grossPrice)

        testTypeProducts.push({
          ...testTypeProduct,
          products: sortedProducts,
        })
      }
    })

    return testTypeProducts
  },
)
