import { useEffect, useState } from 'react'

const useAvailableProductTypes = (availableProductType: any) => {
  const [list, setList] = useState('')

  useEffect(() => {
    try {
      const keys = Object.keys(availableProductType)

      if (!keys?.length) {
        setList('')
      } else {
        setList(keys.join(' • '))
      }
    } catch { } // eslint-disable-line

  }, [availableProductType])

  return { list }
}

export default useAvailableProductTypes
