import styled from 'styled-components'
import Pagination from '@mui/material/Pagination'

export const StyledPagination = styled(Pagination)``

export const StyledPaginationWrapper = styled.div`
    margin: 0 auto;
    display: table;
    padding: 20px 0;
`
