import isValid from 'date-fns/isValid'
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import { Error, NullableNumber } from '@probatix/common/models/global'
import { t } from 'i18next'

/* eslint-disable */
export const isValidEmail = (separator: string = '.') => ({
  message: `global${separator}formValidation${separator}email`,
  value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
})
/* eslint-enable */

export const isValidCharSize = (separator: string = '.') => ({
  message: `global${separator}formValidation${separator}tooShort`,
  value: 2,
})

export const isValidZip = (separator: string = '.') => ({
  message: `global${separator}formValidation${separator}zip`,
  value: /^[0-9]{5}(?:-[0-9]{4})?$/i,
})

export const isValidNumber = (separator: string = '.') => ({
  message: `global${separator}formValidation${separator}number`,
  value: /^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d+)?$/,
})

export const validateDate = (
  separator: string = '.',
  minimumAge?: NullableNumber) => (value: string): boolean | string => {
  const today = new Date()
  const date = new Date(value)
  if (4 > date.getFullYear().toString().length) {
    return `global${separator}formValidation${separator}incorrectFormat`
  }

  if (1900 > date.getFullYear()) {
    return `global${separator}formValidation${separator}dateGreaterThan1900`
  }

  if (!isValid(date)) {
    return `global${separator}formValidation${separator}invalidDate`
  }

  if (!!minimumAge && minimumAge > differenceInCalendarYears(today, new Date(value))) {
    // eslint-disable-next-line max-len
    return `modules${separator}bookTest${separator}TestDetailsPage${separator}validation${separator}ageIsLowerThanMinimumAge`
  }

  return !(date > today) || `global${separator}formValidation${separator}noFutureDate`
}

export const getValidationMessages = (error: { message: string, type: string }, separator: string = '.') => {
  const { message, type } = error || {}

  if ('required' === type) {
    return message || `global${separator}formValidation${separator}required`
  }

  if ('pattern' === type) {
    return message
  }

  return message || null
}

interface ErrorOption {
  message: string
  type?: string
}

export type SetFieldError = (field: string, errorOption: ErrorOption) => void

interface SetServerErrorsParams {
  autoScroll?: boolean
  err: Error
  setFieldError: SetFieldError
  setMainError?: (msg: string) => void
}

export const setServerErrors = ({ autoScroll = true,
  err,
  setFieldError,
  setMainError }: SetServerErrorsParams) => {
  const { detail, title, violations } = err

  if (!violations?.length && title && setMainError) {
    setMainError(detail || t('error.error'))

    return
  }

  violations.forEach((v) => setFieldError(v.propertyPath, { message: v.message }))

  if (autoScroll) {
    document.getElementsByName(violations[0].propertyPath)[0]?.scrollIntoView({ block: 'center' })
  }
}
